import React from "react";
import { Box, Checkbox, Button } from "@mui/material";

interface ProcessTransactionsFilterPanelProps {
  isFiltering: boolean;
  isSearching: boolean;
  isFiltered: boolean;
  onClearFilters: () => void;
  isCheckboxChecked: boolean;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedTransactionsCount: number;
  showPostTransactionWithRule: boolean;
  onPostTransactionWithRule: () => void;
  onApplyRules: () => void;
  onQuickAddSelected: () => void;
}

export const ProcessTransactionsFilterPanel = ({
  isFiltering,
  isSearching,
  isFiltered,
  isCheckboxChecked,
  onClearFilters,
  onCheckboxChange,
  selectedTransactionsCount,
  showPostTransactionWithRule,
  onPostTransactionWithRule,
  onApplyRules,
  onQuickAddSelected,
}: ProcessTransactionsFilterPanelProps) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" my={4} gap={2}>
      <Checkbox
        checked={isCheckboxChecked}
        onChange={onCheckboxChange}
        color="primary"
      />
      <Button variant="outlined" color="secondary" onClick={onApplyRules}>
        Apply Rules
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        disabled={!isSearching && !isFiltering}
        onClick={onClearFilters}
      >
        Clear Filter
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={onQuickAddSelected}
        disabled={selectedTransactionsCount === 0}
      >
        Quick Add {selectedTransactionsCount} selected Transactions
      </Button>
      {showPostTransactionWithRule && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={onPostTransactionWithRule}
        >
          Post {selectedTransactionsCount} transactions with Rule
        </Button>
      )}
    </Box>
  );
};
