import React from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import {
  ShowFilters,
  useFinancialReportStore,
} from "../store/financial-report-store";
import { commonThemes } from "../../../../utils/themes/themes";

const CheckboxLabelByShowFilter = {
  ShowZeroAmountsYN: "Show Zero Amounts",
  ShowAccGroupsYN: "Show Account Groups",
  ShowAccountsYN: "Show Accounts",
  ShowPreviousPeriodYN: "Show Previous Period",
  ReportingEntityYN: "This is a reporting entity per ASIC",
  ShowFooterAuditedUnauditedAU: "Accounts are Audited",
  MultipleSignatoriesYN: "There is more than one ",
  CorporateTrusteeYN: "Show Account Numbers",
};

interface FinancialReportCheckboxesProps {
  ShowTrustDetsYN: boolean;
  ShowMultipleSignatoriesYN: boolean;
  MultipleSignatoryName?: string;
}

export const FinancialReportCheckboxes: React.FC<
  FinancialReportCheckboxesProps
> = ({ ShowTrustDetsYN, ShowMultipleSignatoriesYN, MultipleSignatoryName }) => {
  const showFilters = useFinancialReportStore.use.showFilters();
  const setShowFilterByType = useFinancialReportStore.use.setShowFilterByType();

  const renderCheckbox = (filterKey: keyof ShowFilters) => {
    const isChecked = !!showFilters[filterKey];
    const onChange = () => setShowFilterByType(filterKey, !isChecked);
    const label =
      filterKey === "MultipleSignatoriesYN" && ShowMultipleSignatoriesYN
        ? `${CheckboxLabelByShowFilter[filterKey]} ${MultipleSignatoryName}`
        : CheckboxLabelByShowFilter[filterKey];

    return (
      <FormControlLabel
        key={filterKey}
        value={isChecked}
        control={
          <Checkbox
            checked={isChecked}
            onChange={onChange}
            id="showAccountsNumbers"
          />
        }
        label={label}
        // @ts-ignore
        sx={{ mr: 2, span: { ...commonThemes.normalText } }}
      />
    );
  };

  const shouldRenderCheckbox = (filterKey: string) => {
    if (filterKey === "CorporateTrusteeYN" && !ShowTrustDetsYN) return false;
    if (filterKey === "MultipleSignatoriesYN" && !ShowMultipleSignatoriesYN)
      return false;
    return true;
  };

  return (
    <Box display="flex" flexDirection="column">
      {Object.keys(showFilters)
        .filter(shouldRenderCheckbox)
        .map(renderCheckbox)}
    </Box>
  );
};
