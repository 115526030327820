import React, { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import UserSelfService from "./UserSelfService";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import UserManagement from "./UserManagement";

// CSS Keyframes for the border animation
const borderExpand = {
  "@keyframes expand": {
    "0%": { width: "0%", left: "50%" },
    "100%": { width: "100%", left: "0%" },
  },
};

const UserSelfServiceTabs = () => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const [selectedTab, setSelectedTab] = useState(0);
  const tabLabels = [
    "Self-service user",
    "Self-service employee",
    "Service for user",
    "Service for employee",
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="User Self Service Tabs"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        TabIndicatorProps={{
          style: {
            backgroundColor: "transparent", // Remove default indicator
          },
        }}
      >
        {tabLabels.map((label, index) => {
          const isSelected = selectedTab === index;

          return (
            <Tab
              disableRipple
              key={label}
              value={index}
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    py: 1,
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: 0,
                      left: "50%",
                      width: "0%",
                      height: "2px",
                      backgroundColor: isSelected
                        ? theme.palette.primary.main
                        : "transparent",
                      animation: isSelected ? "expand 0.4s forwards" : "none",
                    },
                    ...borderExpand, // Applying the animation keyframes
                  }}
                >
                  <Typography
                    sx={{
                      color: isSelected
                        ? theme.palette.primary.main
                        : theme.palette.blackWhite.main,
                      fontSize: {
                        xs: "18px", // For small screens
                        sm: "20px", // Slightly bigger on larger mobile screens
                        md: "24px", // Default size for tablets and larger screens
                      },
                      fontWeight: "400",
                      leadingTrim: "both",
                      textEdge: "cap",
                    }}
                  >
                    {label}
                  </Typography>
                </Box>
              }
              sx={{
                minWidth: "auto", // Ensures the tabs don't stretch too much
                padding: {
                  xs: "8px 16px", // Smaller padding on mobile
                  sm: "10px 20px", // Slightly larger on small screens
                  md: "12px 24px", // Default padding on larger screens
                },
                textTransform: "none", // Keep the text as-is
              }}
            />
          );
        })}
      </Tabs>
      {selectedTab === 0 && <UserSelfService />}
      {selectedTab === 1 && <div>Self-service employee content</div>}
      {selectedTab === 2 && <UserManagement />}
      {selectedTab === 3 && <div>Service for employee content</div>}
    </Box>
  );
};

export default UserSelfServiceTabs;
