import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  TextField,
  useMediaQuery,
  Checkbox,
  Box,
  InputLabel,
  Typography,
  Button,
  Autocomplete,
  ListItem,
  Divider,
  List,
} from "@mui/material";
import {
  addMonths,
  startOfMonth,
  endOfMonth,
  addQuarters,
  startOfQuarter,
  endOfQuarter,
  addYears,
  startOfYear,
  endOfYear,
} from "date-fns";
import { commonThemes, darkTheme, lightTheme } from "../../utils/themes/themes";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import ToggleSection from "../ToggleSection/ToggleSections";
import { useTheme } from "../../context/ThemeContext";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers-pro";
import ReportDatesContext from "../../context/ReportDatesContext";
import { enqueueSnackbar } from "notistack";
import "timers-browserify";
import envConfig from "../../config";
import useApi from "../../hooks/useApi";
import { useGetIP } from "../../hooks/get-ip";

const sitesUrl = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/sites/get-selection-list?activeYN=Y&BaseHostURL=${envConfig.mainServiceUrl}`;
const usersUrl = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/users/get-selection-list?activeYN=Y&BaseHostURL=${envConfig.mainServiceUrl}`;
const clientsUrl = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/contacts/get-selection-list?activeYN=Y&typeCS=C&BaseHostURL=${envConfig.mainServiceUrl}`;

// TODO: move to the components/ui
export const CustomShortcuts = (props) => {
  const { items, onChange, isValid, onShortcutClick } = props;

  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map((item) => {
    const newValue = item.getValue({ isValid });

    return {
      label: item.label,
      onClick: () => {
        onShortcutClick(newValue);
      },
      disabled: !isValid(newValue),
    };
  });

  return (
    <Box
      sx={{
        gridRow: 1,
        gridColumn: 2,
      }}
    >
      <List
        dense
        sx={(theme) => ({
          display: "flex",
          "& .MuiListItem-root": {
            pt: 0,
            pl: 1,
          },
        })}
      >
        {resolvedItems.map((item) => {
          return (
            <ListItem sx={{ px: 0, justifyContent: "center" }} key={item.label}>
              {console.log("item", item)}
              <Button
                sx={{
                  fontSize: "12px",
                }}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  item.onClick();
                }}
                disabled={item.disabled}
              >
                {item.label}
              </Button>
            </ListItem>
          );
        })}
      </List>
      <Divider />
    </Box>
  );
};

const ProfitAndLossSettingsModal = ({
  open,
  onClose,
  periodType,
  setPeriodType = () => {},
  dateRange,
  setDateRange = () => {},
  showAccountsNumbers,
  setShowAccountsNumbers = () => {},
  showZeroBalanceAccounts,
  setShowZeroBalanceAccounts = () => {},
  showCashMethod,
  setShowCashMethod = () => {},
  compareValue,
  setCompareValue = () => {},
  compareYearsValue,
  setCompareYearsValue = () => {},
  setGridData = () => {},
  compareChoice,
  setCompareChoice = () => {},
  groupsOrAccounts,
  setGroupsOrAccounts = () => {},
  selectedSites = [],
  setSelectedSites = () => {},
  selectedContacts = [],
  setSelectedContacts = () => {},
  selectedUsers = [],
  setSelectedUsers = () => {},
  sortByAccountName,
  setSortByAccountName = () => {},
  setLastUsedParams = () => {},
}) => {
  const { dbId } = useParams();
  const { isDarkMode } = useTheme();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { reportDates } = useContext(ReportDatesContext);

  const theme = isDarkMode ? darkTheme : lightTheme;
  const breakDropdowns = useMediaQuery("(max-width: 940px)");
  const breakAutocomplete = useMediaQuery("(max-width: 1100px)");
  const breakAdvanced = useMediaQuery("(max-width: 847px)");
  const isMobile = useMediaQuery("(max-width: 620px)");

  const [snapshotData, setSnapshotData] = useState({});

  const [advancedOptionsExpanded, setAdvancedOptionsExpanded] = useState(true);

  // row 4
  const [loading, setLoading] = useState(false);
  const [saveFilters, setSaveFilters] = useState(true);
  const controllerName = "accounting";
  const actionName = "get-profit-loss-periods-grid-rs1";
  const apiUrl = `https://${envConfig.apiDev2}/api/en-au/${controllerName}/${actionName}?BaseHostURL=${envConfig.mainServiceUrl}`;

  const { apiCall } = useApi();
  const ip = useGetIP();

  const [sitesData, setSitesData] = useState(null);
  const [usersData, setUsersData] = useState(null);
  const [clientsData, setClientsData] = useState(null);

  const fetchSitesData = async () => {
    await apiCall({
      url: sitesUrl,
      method: "GET",
      onSuccess: (data) => {
        setSitesData(data);
      },
      onError: (error) => {
        enqueueSnackbar(error, { variant: "error" });
      },
    });
  };

  const fetchUsersData = async () => {
    await apiCall({
      url: usersUrl,
      method: "GET",
      onSuccess: (data) => {
        setUsersData(data);
      },
      onError: (error) => {
        enqueueSnackbar(error, { variant: "error" });
      },
    });
  };

  const fetchClientsData = async () => {
    await apiCall({
      url: clientsUrl,
      method: "GET",
      onSuccess: (data) => {
        setClientsData(data);
      },
      onError: (error) => {
        enqueueSnackbar(error, { variant: "error" });
      },
    });
  };

  useEffect(() => {
    fetchSitesData();
    fetchUsersData();
    fetchClientsData();
  }, []);

  const handleStartDateChange = (newStartDate) => {
    setDateRange([newStartDate, dateRange[1]]);
    setPeriodType("custom");
  };

  useEffect(() => {
    if (reportDates?.reportsStartDate) {
      if (reportDates?.reportsEndDate) {
        const formattedStartDate = new Date(reportDates?.reportsStartDate + "Z")
          .toISOString()
          .split("T")[0];
        const formattedEndDate = new Date(reportDates?.reportsEndDate + "Z")
          .toISOString()
          .split("T")[0];
        const dayjsStartDate = dayjs(formattedStartDate);
        const dayjsEndDate = dayjs(formattedEndDate);
        setDateRange([dayjsStartDate, dayjsEndDate]);
        setPeriodType("custom");
        return;
      }
      const formattedStartDate = new Date(reportDates?.reportsStartDate + "Z")
        .toISOString()
        .split("T")[0];
      const dayjsStartDate = dayjs(formattedStartDate);
      setDateRange([dayjsStartDate, null]);
      setPeriodType("custom");
    } else if (reportDates?.reportsEndDate) {
      const formattedEndDate = new Date(reportDates?.reportsEndDate + "Z")
        .toISOString()
        .split("T")[0];
      const dayjsEndDate = dayjs(formattedEndDate);
      setDateRange([null, dayjsEndDate]);
      setPeriodType("custom");
    }
  }, [reportDates]);

  const handleEndDateChange = (newEndDate) => {
    setDateRange([dateRange[0], newEndDate]);
    setPeriodType("custom");
  };

  const handleCompareValueChange = (event) => {
    setCompareValue(event.target.value);
    if (event.target.value) {
      setCompareChoice("earlierPeriods");
    }
  };

  const handleCompareYearsValueChange = (event) => {
    setCompareYearsValue(event.target.value);
    if (event.target.value) {
      setCompareChoice("earlierYears");
    }
  };

  const handleComparePeriodChange = (event) => {
    if (event.target.value === "none") {
      setCompareValue(0);
      setCompareYearsValue(0);
    } else {
      if (compareValue === 0) {
        setCompareValue(1);
      }
      if (compareYearsValue === 0) {
        setCompareYearsValue(1);
      }
    }
    setCompareChoice(event.target.value);
  };

  const handlePeriodTypeChange = (event) => {
    const newPeriodType = event.target.value;
    if (newPeriodType === "custom") {
      setDateRange([null, null]);
    } else {
      let startDate, endDate;
      const currentDate = new Date();
      const isCurrent = newPeriodType.startsWith("current_");
      const isPrevious = newPeriodType.startsWith("previous_");
      const actualPeriodType = newPeriodType.split("_").pop();

      if (isCurrent || isPrevious) {
        if (actualPeriodType === "month") {
          startDate = isCurrent
            ? startOfMonth(currentDate)
            : startOfMonth(addMonths(currentDate, -1));
          endDate = isCurrent
            ? endOfMonth(currentDate)
            : endOfMonth(addMonths(currentDate, -1));
        } else if (actualPeriodType === "quarter") {
          startDate = isCurrent
            ? startOfQuarter(currentDate)
            : startOfQuarter(addQuarters(currentDate, -1));
          endDate = isCurrent
            ? endOfQuarter(currentDate)
            : endOfQuarter(addQuarters(currentDate, -1));
        } else if (actualPeriodType === "financialYear") {
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth();

          if (currentMonth >= 6) {
            startDate = isCurrent
              ? new Date(currentYear, 6, 1)
              : new Date(currentYear - 1, 6, 1);
            endDate = isCurrent
              ? new Date(currentYear + 1, 5, 30)
              : new Date(currentYear, 5, 30);
          } else {
            startDate = isCurrent
              ? new Date(currentYear - 1, 6, 1)
              : new Date(currentYear - 2, 6, 1);
            endDate = isCurrent
              ? new Date(currentYear, 5, 30)
              : new Date(currentYear - 1, 5, 30);
          }
        } else if (actualPeriodType === "calendarYear") {
          startDate = isCurrent
            ? startOfYear(currentDate)
            : startOfYear(addYears(currentDate, -1));
          endDate = isCurrent
            ? endOfYear(currentDate)
            : endOfYear(addYears(currentDate, -1));
        }
      }

      const formattedStartDate = startDate.toISOString().split("T")[0];
      const formattedEndDate = new Date(endDate).toISOString().split("T")[0];

      const dayjsStartDate = dayjs(formattedStartDate);
      const dayjsEndDate = dayjs(formattedEndDate);
      setDateRange([dayjsStartDate, dayjsEndDate]);
    }

    setPeriodType(newPeriodType);
  };

  const updateUrlParams = (params) => {
    const searchParams = new URLSearchParams(params);
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState(null, "", newUrl);
  };

  const handleCancel = () => {
    setPeriodType(snapshotData.periodType);
    setDateRange(snapshotData.dateRange);
    setShowAccountsNumbers(snapshotData.showAccountsNumbers);
    setShowZeroBalanceAccounts(snapshotData.showZeroBalanceAccounts);
    setShowCashMethod(snapshotData.showCashMethod);
    setCompareValue(snapshotData.compareValue);
    setCompareYearsValue(snapshotData.compareYearsValue);
    setCompareChoice(snapshotData.compareChoice);
    setGroupsOrAccounts(snapshotData.groupsOrAccounts);
    setSelectedSites(snapshotData.selectedSites);
    setSelectedContacts(snapshotData.selectedContacts);
    setSelectedUsers(snapshotData.selectedUsers);
    setSaveFilters(true);
  };

  const handleSnapshot = () => {
    setSnapshotData({
      periodType,
      dateRange,
      showAccountsNumbers,
      showZeroBalanceAccounts,
      showCashMethod,
      compareValue,
      compareYearsValue,
      compareChoice,
      groupsOrAccounts,
      selectedSites,
      selectedContacts,
      selectedUsers,
      saveFilters,
    });
  };

  useEffect(() => {
    const filtersFromMemory = localStorage.getItem(`profitLossFilters-${dbId}`);
    const parsedFilters = JSON.parse(filtersFromMemory);
    const parsedDateRange = parsedFilters?.dateRange
      ? parsedFilters.dateRange.map((dateString) =>
          dateString ? dayjs(dateString) : null,
        )
      : [null, null];
    setDateRange(parsedDateRange);
    setCompareValue(parsedFilters?.compareValue || compareValue || 0);
    setCompareYearsValue(
      parsedFilters?.compareYearsValue || compareYearsValue || 0,
    );

    setPeriodType(parsedFilters?.periodType || "current_financialYear");

    setGroupsOrAccounts(parsedFilters?.groupsOrAccounts || "accountsOnly");
    setShowAccountsNumbers(parsedFilters?.showAccountsNumbers || true);
    setShowZeroBalanceAccounts(parsedFilters?.showZeroBalanceAccounts || false);
    setShowCashMethod(parsedFilters?.showCashMethod || false);

    setCompareChoice(parsedFilters?.compareChoice || "none");
    setSelectedSites(parsedFilters?.selectedSites || []);
    setSelectedContacts(parsedFilters?.selectedContacts || []);
    setSelectedUsers(parsedFilters?.selectedUsers || []);
    setAdvancedOptionsExpanded(parsedFilters?.advancedOptionsExpanded || true);
    setSaveFilters(parsedFilters?.saveFilters || true);
  }, []);

  useEffect(() => {
    if (!open) {
      setSnapshotData({});
    } else {
      handleSnapshot();
    }
  }, [open]);

  const handleSaveFilters = () => {
    // if (saveFilters) {
    const filtersToSave = {
      dateRange: dateRange.map((date) => (date ? date.toJSON() : null)),

      compareValue,
      compareYearsValue,
      groupsOrAccounts,
      showAccountsNumbers,
      showCashMethod,
      showZeroBalanceAccounts,
      periodType,
      compareChoice,
      selectedSites,
      selectedContacts,
      selectedUsers,
      advancedOptionsExpanded,
      saveFilters,
    };
    localStorage.setItem(
      `profitLossFilters-${dbId}`,
      JSON.stringify(filtersToSave),
    );
    // }
  };

  const handleApplyFilters = () => {
    let startDate, endDate;

    if (periodType === "custom") {
      startDate = dateRange[0];
      endDate = dateRange[1];
      if (!startDate || !endDate) return;
    } else {
      const currentDate = new Date();
      const isCurrent = periodType.startsWith("current_");
      const isPrevious = periodType.startsWith("previous_");
      const actualPeriodType = periodType.split("_").pop();

      if (isCurrent || isPrevious) {
        if (actualPeriodType === "month") {
          startDate = isCurrent
            ? startOfMonth(currentDate)
            : startOfMonth(addMonths(currentDate, -1));
          endDate = isCurrent
            ? endOfMonth(currentDate)
            : endOfMonth(addMonths(currentDate, -1));
        } else if (actualPeriodType === "quarter") {
          startDate = isCurrent
            ? startOfQuarter(currentDate)
            : startOfQuarter(addQuarters(currentDate, -1));
          endDate = isCurrent
            ? endOfQuarter(currentDate)
            : endOfQuarter(addQuarters(currentDate, -1));
        } else if (actualPeriodType === "financialYear") {
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth();

          if (currentMonth >= 6) {
            startDate = isCurrent
              ? new Date(currentYear, 6, 1)
              : new Date(currentYear - 1, 6, 1);
            endDate = isCurrent
              ? new Date(currentYear + 1, 5, 30)
              : new Date(currentYear, 5, 30);
          } else {
            startDate = isCurrent
              ? new Date(currentYear - 1, 6, 1)
              : new Date(currentYear - 2, 6, 1);
            endDate = isCurrent
              ? new Date(currentYear, 5, 30)
              : new Date(currentYear - 1, 5, 30);
          }
        } else if (actualPeriodType === "calendarYear") {
          startDate = isCurrent
            ? startOfYear(currentDate)
            : startOfYear(addYears(currentDate, -1));
          endDate = isCurrent
            ? endOfYear(currentDate)
            : endOfYear(addYears(currentDate, -1));
        }
      }
    }

    let sYear, sMonth, sDay;
    let eYear, eMonth, eDay;
    if (!startDate || !endDate) return;
    if (startDate?.$d) {
      sYear = startDate?.year();
      sMonth = startDate?.month() + 1;
      sDay = startDate?.date();
    } else {
      sYear = startDate?.getFullYear();
      sMonth = startDate?.getMonth() + 1;
      sDay = startDate?.getDate();
    }

    sMonth = sMonth < 10 ? "0" + sMonth : sMonth;
    sDay = sDay < 10 ? "0" + sDay : sDay;

    const formattedStartDate = `${sYear}-${sMonth}-${sDay}`;

    if (endDate.$d) {
      eYear = endDate.year();
      eMonth = endDate.month() + 1;
      eDay = endDate.date();
    } else {
      eYear = endDate.getFullYear();
      eMonth = endDate.getMonth() + 1;
      eDay = endDate.getDate();
    }

    eMonth = eMonth < 10 ? "0" + eMonth : eMonth;
    eDay = eDay < 10 ? "0" + eDay : eDay;

    const formattedEndDate = `${eYear}-${eMonth}-${eDay}`;

    const params = new URLSearchParams({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      cashMethod: showCashMethod ? "Y" : "N",
      compareChoice,
      compareValue,
      compareYearsValue,
      groupsOrAccounts,
      showAccountsNumbers: showAccountsNumbers ? "Y" : "N",
      showZeroBalanceAccounts: showZeroBalanceAccounts ? "Y" : "N",
      selectedSites: selectedSites.map((site) => site.dpsID).join(","),
      selectedContacts: selectedContacts
        .map((contact) => contact.cntId)
        .join(","),
      selectedUsers: selectedUsers.map((user) => user.usrID).join(","),
    });

    navigate(`?${params.toString()}`);
    onClose();
  };

  useEffect(() => {
    const params = new URLSearchParams(search);

    const requestData = {
      StartDate: params.get("startDate"),
      EndDate: params.get("endDate"),
      CashMethodYN: params.get("cashMethod"),
      PreviousPeriodType_GE14:
        params.get("compareChoice") === "earlierYears"
          ? 1
          : params.get("compareChoice") === "earlierPeriods"
            ? 2
            : 0,
      DpsList: params.get("selectedSites")?.split(","),
      UsrList: params.get("selectedUsers")?.split(","),
      CntList: params.get("selectedContacts")?.split(","),
      ShowZeroAmountsYN: params.get("showZeroBalanceAccounts"),
      ShowAccountNumbersYN: "N",
      NumberOfPeriods:
        params.get("compareChoice") === "earlierYears"
          ? Number(params.get("compareYearsValue")) + 1
          : params.get("compareChoice") === "earlierPeriods"
            ? Number(params.get("compareValue")) + 1
            : 1,
      PresentationTypeAccountsHeadersBothAHB:
        params.get("groupsOrAccounts") === "groupsOnly"
          ? "H"
          : params.get("groupsOrAccounts") === "accountsOnly"
            ? "A"
            : "B",
      Acc001: "string",
      BuildHeadersAndDividersYN: "Y",
      ShowPrintDetailsYN: "N",
      ShowFooterNoAuditedUnauditedNAU: "N",
      OrderLatestonleftOldestonleftLO: "O",
      SetColumnNamesForPdfYN: "N",
      SortBy_M_Name_N_Number: sortByAccountName ? "M" : "N",
    };

    const fetchData = async () => {
      setLoading(true);
      await apiCall({
        url: apiUrl,
        ip: ip,
        method: "POST",
        body: requestData,
        onSuccess: (result) => {
          setGridData(result);
          setLoading(false);
        },
        onError: (errorMessage) => {
          enqueueSnackbar(errorMessage, { variant: "error" });
          setGridData(null);
          setLoading(false);
        },
      });
    };

    if (params.get("startDate") && params.get("endDate")) {
      fetchData();
    }
  }, [search]);

  const handleSelectChange = (category, selectedValues) => {
    switch (category) {
      case "sites":
        setSelectedSites(selectedValues);
        break;
      case "contacts":
        setSelectedContacts(selectedValues);
        break;
      case "users":
        setSelectedUsers(selectedValues);
        break;
      default:
        break;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleCancel();
        onClose();
      }}
      sx={{
        ">div>div": { maxWidth: "xl", padding: isMobile ? 0 : 2 },
      }}
    >
      <DialogTitle
        sx={{
          fontFamily: theme.typography.fontFamily,
          fontSize: isMobile ? 18 : theme.h1.fontSize,
          fontWeight: theme.typography.fontWeightThin,
        }}
      >
        Profit and Loss Report Settings
      </DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <div
          style={{
            display: "flex",
            alignItems: breakDropdowns && !isMobile ? "flex-start" : "center",
            flexDirection: isMobile ? "column" : "row",
            marginBottom: "19px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: breakDropdowns ? "column" : "row",
            }}
          >
            <FormControl
              variant="outlined"
              size="small"
              sx={{ minWidth: "260px" }}
              style={{
                marginLeft: "16px",
                marginRight: "16px",
                marginTop: "15px",
              }}
            >
              <InputLabel
                sx={{
                  fontSize: 12,
                  ...commonThemes.inputLabel,
                  color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                }}
              >
                Predefined Period
              </InputLabel>
              <Select
                label="Predefined Period"
                labelId="Predefined_Period"
                value={periodType}
                onChange={handlePeriodTypeChange}
              >
                <MenuItem sx={{ fontSize: "12px" }} value="current_month">
                  Current Month
                </MenuItem>
                <MenuItem sx={{ fontSize: "12px" }} value="current_quarter">
                  Current Quarter
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: "12px" }}
                  value="current_financialYear"
                >
                  Current Financial Year
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: "12px" }}
                  value="current_calendarYear"
                >
                  Current Calendar Year
                </MenuItem>
                <MenuItem sx={{ fontSize: "12px" }} value="previous_month">
                  Previous Month
                </MenuItem>
                <MenuItem sx={{ fontSize: "12px" }} value="previous_quarter">
                  Previous Quarter
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: "12px" }}
                  value="previous_financialYear"
                >
                  Previous Financial Year
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: "12px" }}
                  value="previous_calendarYear"
                >
                  Previous Calendar Year
                </MenuItem>
                <MenuItem sx={{ fontSize: "12px" }} value="custom">
                  Custom
                </MenuItem>
              </Select>
            </FormControl>

            <Box
              sx={{
                pt: 2,
                textAlign: "center",
                position: "relative",
                width: "100%",
              }}
            >
              <span
                style={{
                  ...commonThemes.normalText,
                  marginRight: "32px",
                  marginLeft: "14px",
                  marginTop: isMobile ? "15px" : "20px",
                  fontSize: theme.typography.fontSize,
                  fontWeight: theme.typography.fontWeightBold,
                }}
              >
                OR
              </span>
              {isMobile && (
                <Button
                  sx={{
                    mt: "7px",
                    p: 1,
                    position: "absolute",
                    top: "50%",
                    right: 0,
                    transform: "translateY(-50%)",
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: theme.palette.primary.main,
                    },
                  }}
                  disabled={loading}
                  onClick={() => {
                    handleSaveFilters();
                    handleApplyFilters();
                  }}
                  variant="primary"
                >
                  Show Report
                </Button>
              )}
            </Box>
            <FormControl
              size="small"
              component="fieldset"
              sx={{
                minWidth: "fit-content",
                marginTop: "15px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                {/* TODO: Replace with OlivsDatePicker component? */}
                <DatePicker
                  label="Start Date"
                  maxDate={dateRange[1]}
                  value={dateRange[0]}
                  onChange={handleStartDateChange}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{
                    minWidth: "145px",
                    maxWidth: "145px",
                    mr: 0.5,
                    div: { borderRadius: "8px" },
                    input: {
                      fontSize: 12,
                    },
                    label: {
                      fontSize: 12,
                      ...commonThemes.inputLabel,
                      color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                    },
                    legend: {
                      span: {
                        width: "59px",
                      },
                    },
                  }}
                  slots={{
                    shortcuts: CustomShortcuts,
                  }}
                  slotProps={{
                    shortcuts: {
                      changeImportance: "set",
                      onShortcutClick: (newValue) => {
                        handleStartDateChange(newValue);
                      },
                      items: [
                        {
                          label: "Previous year",
                          getValue: () =>
                            dayjs(dateRange[0]).subtract(1, "year"),
                        },
                        {
                          label: "Next year",
                          getValue: () => dayjs(dateRange[0]).add(1, "year"),
                        },
                        {
                          label: "Today",
                          getValue: () => dayjs(),
                        },
                      ],
                    },
                  }}
                />
                <DatePicker
                  label="End Date"
                  value={dateRange[1]}
                  minDate={dateRange[0]}
                  onChange={handleEndDateChange}
                  sx={{
                    ml: 0.5,
                    minWidth: "145px",
                    maxWidth: "145px",
                    div: { borderRadius: "8px" },
                    input: {
                      fontSize: 12,
                    },
                    label: {
                      fontSize: 12,
                      ...commonThemes.inputLabel,
                      color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                    },
                    legend: {
                      span: {
                        width: "59px",
                      },
                    },
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  slots={{
                    shortcuts: CustomShortcuts,
                  }}
                  slotProps={{
                    shortcuts: {
                      changeImportance: "set",
                      onShortcutClick: (newValue) => {
                        handleEndDateChange(newValue);
                      },
                      items: [
                        {
                          label: "Previous year",
                          getValue: () =>
                            dayjs(dateRange[1]).subtract(1, "year"),
                        },
                        {
                          label: "Next year",
                          getValue: () => dayjs(dateRange[1]).add(1, "year"),
                        },
                        {
                          label: "Today",
                          getValue: () => dayjs(),
                        },
                      ],
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: isMobile ? "center" : "flex-start",
          }}
        >
          <FormLabel
            component="legend"
            sx={{
              textAlign: isMobile ? "left" : "unset",
              width: isMobile ? "100%" : "unset",
              marginLeft: isMobile ? 5 : "unset",
              marginRight: 5,
              pt: 1,
              ...theme.blueLabel,
            }}
          >
            Compare this period
          </FormLabel>
          <RadioGroup
            sx={{
              flexDirection: breakAutocomplete ? "column" : "row",
              alignItems:
                breakAutocomplete && !isMobile ? "flex-start" : "center",
              fontSize: theme.typography.fontSize,
            }}
            value={compareChoice}
            onChange={handleComparePeriodChange}
            row
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                sx={{
                  span: { fontSize: theme.typography.fontSize },
                  marginRight: 1,
                  ...theme.normalText,
                }}
                value="earlierPeriods"
                checked={compareChoice === "earlierPeriods"}
                control={<Radio />}
                label={`With `}
              />
              <TextField
                type="tel"
                sx={{
                  maxWidth: "58px",
                  width: "100%",
                  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },

                  "input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  input: { maxHeight: "12px", padding: "10px 6px 10px 14px" },
                  label: { fontSize: 16, marginTop: 0 },
                }}
                inputProps={{
                  min: 1,
                  max: 23,
                  step: 1,
                }}
                label={``}
                value={compareValue}
                onFocus={(event) => event.target.select()}
                onChange={handleCompareValueChange}
              />
              <Typography
                sx={{ ml: "8px", ...commonThemes.normalText }}
              >{`previous ${
                periodType === "current_month" ||
                periodType === "previous_month"
                  ? "months"
                  : periodType === "current_quarter" ||
                      periodType === "previous_quarter"
                    ? "quarter"
                    : periodType === "current_financialYear" ||
                        periodType === "previous_financialYear"
                      ? "year"
                      : periodType === "custom"
                        ? "period"
                        : periodType === "current_calendarYear" ||
                            periodType === "previous_calendarYear"
                          ? "calendar year"
                          : periodType
              }(s)`}</Typography>
            </Box>
            {(periodType !== "current_financialYear" ||
              periodType !== "previous_financialYear") &&
              (periodType !== "current_calendarYear" ||
                periodType !== "previous_calendarYear") && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: isMobile ? "100%" : "unset",
                  }}
                >
                  <FormControlLabel
                    sx={{
                      span: {
                        fontSize: theme.typography.fontSize,
                        ...theme.normalText,
                      },
                      marginLeft: isMobile || breakAutocomplete ? "-11px" : 5,
                      marginRight: 1,
                    }}
                    value="earlierYears"
                    checked={compareChoice === "earlierYears"}
                    control={<Radio />}
                    label={`Across `}
                  />
                  <TextField
                    type="number"
                    sx={{
                      maxWidth: "58px",
                      marginRight: 1,
                      input: {
                        maxHeight: "12px",
                        padding: "10px 6px 10px 14px",
                      },
                    }}
                    inputProps={{
                      min: 1,
                      max: 23,
                      step: 1,
                    }}
                    label={``}
                    value={compareYearsValue}
                    onChange={handleCompareYearsValueChange}
                  />
                  <Typography
                    sx={{
                      ...theme.normalText,
                      fontSize: theme.typography.fontSize,
                    }}
                  >{` previous year(s)`}</Typography>
                </div>
              )}
            <FormControlLabel
              sx={{
                width: isMobile ? "100%" : "unset",
                span: {
                  fontSize: theme.typography.fontSize,
                  ...commonThemes.normalText,
                },
                marginLeft: isMobile
                  ? "-5px"
                  : breakAutocomplete && !isMobile
                    ? "-11px"
                    : 5,
              }}
              value="none"
              control={<Radio />}
              label="Don't compare"
              checked={compareChoice === "none"}
            />
          </RadioGroup>
        </div>
      </DialogContent>
      <Box
        sx={{
          paddingLeft: 3,
          paddingRight: 3,
          marginTop: "20px",
        }}
      >
        <ToggleSection
          expanded={advancedOptionsExpanded}
          onClick={() => setAdvancedOptionsExpanded(!advancedOptionsExpanded)}
          title="Advanced Options"
        >
          <div>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <FormLabel
                sx={{
                  minWidth: "181px",
                  mt: 1,
                  ...theme.blueLabel,
                }}
              >
                Show
              </FormLabel>
              <Box
                sx={{
                  display: "flex",
                  flexDirection:
                    breakAdvanced && !isMobile ? "row-reverse" : "column",
                  ml: isMobile ? -14 : 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: breakAdvanced ? "column" : "row",
                  }}
                >
                  <FormControlLabel
                    value={!!showAccountsNumbers}
                    control={
                      <Checkbox
                        checked={!!showAccountsNumbers}
                        onChange={() =>
                          setShowAccountsNumbers(!showAccountsNumbers)
                        }
                        id="showAccountsNumbers"
                      />
                    }
                    label="Accounts numbers"
                    sx={{ mr: 2, span: { ...commonThemes.normalText } }}
                  />
                  <FormControlLabel
                    value={!!showZeroBalanceAccounts}
                    control={
                      <Checkbox
                        checked={!!showZeroBalanceAccounts}
                        onChange={() => {
                          setShowZeroBalanceAccounts(!showZeroBalanceAccounts);
                        }}
                        id="showZeroBalance"
                      />
                    }
                    label="Zero balance accounts"
                    sx={{ mr: 2, span: { ...commonThemes.normalText } }}
                  />
                  <FormControlLabel
                    value={!!showCashMethod}
                    control={
                      <Checkbox
                        checked={!!showCashMethod}
                        onChange={() => setShowCashMethod(!showCashMethod)}
                        id="showCashMethod"
                      />
                    }
                    label="Cash method"
                    sx={{ mr: 2, span: { ...commonThemes.normalText } }}
                  />
                </Box>

                <RadioGroup
                  sx={{
                    display: "flex",
                    flexDirection: breakAdvanced ? "column" : "row",
                  }}
                >
                  <FormControlLabel
                    value="groupsOnly"
                    onChange={() => setGroupsOrAccounts("groupsOnly")}
                    control={
                      <Radio checked={groupsOrAccounts === "groupsOnly"} />
                    }
                    label="Groups only"
                    sx={{ mr: 2, span: { ...commonThemes.normalText } }}
                  />
                  <FormControlLabel
                    value="groupsAndAccounts"
                    onChange={() => setGroupsOrAccounts("groupsAndAccounts")}
                    control={
                      <Radio
                        checked={groupsOrAccounts === "groupsAndAccounts"}
                      />
                    }
                    label="Groups and accounts"
                    sx={{ mr: 2, span: { ...commonThemes.normalText } }}
                  />
                  <FormControlLabel
                    value="accountsOnly"
                    onChange={() => setGroupsOrAccounts("accountsOnly")}
                    control={
                      <Radio checked={groupsOrAccounts === "accountsOnly"} />
                    }
                    label="Accounts Only"
                    sx={{ mr: 2, span: { ...commonThemes.normalText } }}
                  />
                </RadioGroup>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", mb: "36px" }}>
              <FormLabel
                sx={{
                  minWidth: "181px",
                  mt: 1,
                  ...theme.blueLabel,
                }}
              >
                Sort by
              </FormLabel>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  ml: isMobile ? -14 : 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: breakAdvanced ? "column" : "row",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={sortByAccountName}
                        onChange={() => setSortByAccountName(true)}
                        value="true"
                        name="sortByAccount"
                      />
                    }
                    label="Account Name"
                    sx={{ mr: 2, span: { ...commonThemes.normalText } }}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={!sortByAccountName}
                        onChange={() => setSortByAccountName(false)}
                        value="false"
                        name="sortByAccount"
                      />
                    }
                    label="Account Number"
                    sx={{ mr: 2, span: { ...commonThemes.normalText } }}
                  />
                </Box>
              </Box>
            </Box>

            <div style={{ marginBottom: "12px" }}>
              <FormLabel
                sx={{
                  display: breakAutocomplete ? "flex" : "inline-block",
                  minWidth: "181px",
                  marginTop: "16px",
                  ...theme.blueLabel,
                }}
              >
                Restrict transactions to
              </FormLabel>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: breakDropdowns ? "column" : "row",
                  textAlign: isMobile ? "center" : "unset",
                  paddingLeft: breakDropdowns && !isMobile ? "180px" : "auto",
                  marginTop: breakDropdowns && !isMobile ? "-38px" : "0",
                }}
              >
                <FormControl sx={{ mt: 1 }} size="small" variant="outlined">
                  <Autocomplete
                    multiple
                    id="sites-autocomplete"
                    options={sitesData?.ListOfSites || []}
                    getOptionLabel={(option) => option.dpsName}
                    value={selectedSites}
                    onChange={(_, newValue) => {
                      handleSelectChange("sites", newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.dpsID === value.dpsID
                    }
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sites"
                        sx={{
                          label: {
                            fontSize: 12,
                            ...commonThemes.inputLabel,
                            color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                          },
                          minWidth: "240px",
                          maxWidth: "240px",
                          marginRight: 2,
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ mt: 1 }} size="small" variant="outlined">
                  <Autocomplete
                    multiple
                    id="contacts-autocomplete"
                    options={clientsData?.ListOfContacts || []}
                    getOptionLabel={(option) => option.cntName}
                    value={selectedContacts}
                    onChange={(_, newValue) => {
                      handleSelectChange("contacts", newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.cntId === value.cntId
                    }
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Contacts"
                        sx={{
                          label: {
                            fontSize: 12,
                            ...commonThemes.inputLabel,
                            color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                          },
                          minWidth: "240px",
                          maxWidth: "240px",
                          marginRight: 2,
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ mt: 1 }} size="small" variant="outlined">
                  <Autocomplete
                    multiple
                    id="users-autocomplete"
                    options={usersData?.ListOfUsers || []}
                    getOptionLabel={(user) => `${user.usrName || ""}`}
                    value={selectedUsers}
                    onChange={(_, newValue) => {
                      handleSelectChange("users", newValue);
                    }}
                    isOptionEqualToValue={(user, value) =>
                      user.usrID === value.usrID
                    }
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Users"
                        sx={{
                          label: {
                            fontSize: 12,
                            ...commonThemes.inputLabel,
                            color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                          },
                          minWidth: "240px",
                          maxWidth: "240px",
                          marginRight: 2,
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </div>
          </div>
        </ToggleSection>
      </Box>

      <DialogActions sx={{ pt: 0, mt: 5 }}>
        <Button
          sx={{
            "&:hover": {
              bgcolor: "rgba(132, 159, 35, 0.04)",
            },
          }}
          onClick={() => {
            handleCancel();
            onClose();
          }}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          sx={{
            "&.MuiButtonBase-root:hover": {
              bgcolor: theme.palette.primary.main,
            },
          }}
          disabled={loading}
          onClick={() => {
            handleSaveFilters();
            handleApplyFilters();
          }}
          variant="primary"
        >
          Show Report
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProfitAndLossSettingsModal;
