import React from "react";
import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePredefinedPeriodPicker,
  PeriodType,
} from "../../../../components/ui/date-predefined-period-picker/date-predefined-period-picker";
import { OlivsDataPicker } from "../../../../components/ui/date-picker/date-picker";
import { useFinancialReportStore } from "../store/financial-report-store";
import { getPredefinedDates } from "../../../../utils/format-date";

export const FinancialReportDatePickers = () => {
  const startDate = useFinancialReportStore.use.startDate();
  const endDate = useFinancialReportStore.use.endDate();
  const setStartDate = useFinancialReportStore.use.setStartDate();
  const setEndDate = useFinancialReportStore.use.setEndDate();

  const predefinedPeriod = useFinancialReportStore.use.predefinedPeriod();
  const setPredefinedPeriod = useFinancialReportStore.use.setPredefinedPeriod();

  const handleStartDateChange = (newStartDate) => {
    setStartDate(newStartDate);
    setPredefinedPeriod("custom");
  };

  const handleEndDateChange = (newEndDate) => {
    setEndDate(newEndDate);
    setPredefinedPeriod("custom");
  };

  const handlePredefinedPeriodChange = (periodType: PeriodType) => {
    setPredefinedPeriod(periodType);
    const predefinedDates = getPredefinedDates(periodType);
    if (predefinedDates) {
      setStartDate(predefinedDates[0]);
      setEndDate(predefinedDates[1]);
    }
  };

  return (
    <>
      <Box
        sx={{ width: "100%" }}
        display={"flex"}
        alignItems={"center"}
        gap={2}
        mt={4}
        maxWidth={800}
      >
        <DatePredefinedPeriodPicker
          value={predefinedPeriod}
          onChange={handlePredefinedPeriodChange}
          sx={{
            width: "100%",
            // height: "40px",
            "& .MuiInputBase-input": {
              fontSize: "16px",
              lineHeight: "22px",
            },
          }}
        />
        <Box>OR</Box>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <OlivsDataPicker
            label="Start Date"
            maxDate={endDate}
            value={startDate}
            onChange={handleStartDateChange}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: undefined,
              },
              width: "100%",
            }}
          />
          <OlivsDataPicker
            label="End Date"
            minDate={startDate}
            value={endDate}
            onChange={handleEndDateChange}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: undefined,
              },
              width: "100%",
            }}
          />
        </LocalizationProvider>
      </Box>
    </>
  );
};
