import { create } from "zustand";
import { createSelectors } from "../../../../utils/createSelectors";
import { Contact } from "../../../accounting/api/list-of-contacts";
import { Site } from "../../../accounting/api/list-of-sites";
import { TaxCode } from "../../tax-codes/api/list-of-tax-codes";

interface State {
  listOfTaxCodes: TaxCode[];
  listOfContacts: Contact[];
  listOfSites: Site[];
}

interface Actions {
  setListOfTaxCodes: (lTaxCodes: TaxCode[]) => void;
  setListOfContacts: (lContacts: Contact[]) => void;
  setListOfSites: (lSites: Site[]) => void;
}

export const useStoreListOfDependenciesBase = create<State & Actions>()(
  (set) => ({
    listOfTaxCodes: [],
    listOfContacts: [],
    listOfSites: [],
    setListOfTaxCodes: (lTaxCodes) =>
      set(() => ({ listOfTaxCodes: lTaxCodes })),
    setListOfContacts: (lContacts) =>
      set(() => ({ listOfContacts: lContacts })),
    setListOfSites: (lSites) => set(() => ({ listOfSites: lSites })),
  }),
);

export const useStoreProcessTransactionsDependencies = createSelectors(
  useStoreListOfDependenciesBase,
);
