import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  Grid,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  FormControl,
  CircularProgress,
  LinearProgress,
  Container,
  IconButton,
  Checkbox,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import dayjs from "dayjs"; // For date formatting
import { createSessionForExternal } from "../../utils/reusable";
import { getCookie } from "../../utils/api";

const TparRecordDetails = () => {
  const { dbId, lang, RecordID } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { apiCall } = useApi();

  const [recordData, setRecordData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  // Form state
  const [noAbnStatementYN, setNoAbnStatementYN] = useState("");
  const [grossAmt, setGrossAmt] = useState("");
  const [gstAmt, setGstAmt] = useState("");
  const [taxW, setTaxW] = useState("");
  const [amendmentYN, setAmendmentYN] = useState("");
  const [pmtType, setPmtType] = useState("");
  const [grantName, setGrantName] = useState("");
  const [grantPmtDate, setGrantPmtDate] = useState("");
  const [grantNaneYNU, setGrantNaneYNU] = useState("");
  const [notes, setNotes] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    fetchRecordDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RecordID]);

  const fetchRecordDetails = () => {
    setLoading(true);
    apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/accounting/tpar-get-record-details?RecordID=${RecordID}&BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "GET",
      onSuccess: (data) => {
        setRecordData(data);
        setNoAbnStatementYN(data.NoAbnStatementYN === "Y" ? "Yes" : "No");
        setGrossAmt(data.GrossAmt ? data.GrossAmt.toString() : "");
        setGstAmt(data.GstAmt ? data.GstAmt.toString() : "");
        setTaxW(data.TaxW ? data.TaxW.toString() : "");
        setAmendmentYN(data.AmendmentYN);
        setPmtType(data.PmtType || "");
        setGrantName(data.GrantName || "");
        setGrantPmtDate(
          data.GrantPmtDate
            ? dayjs(data.GrantPmtDate).format("YYYY-MM-DD")
            : "",
        );
        setGrantNaneYNU(data.GrantNaneYNU || "");
        setNotes(data.Notes || "");
        setStatus(data.Status || "");
        setLoading(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage || "Error fetching record details.", {
          variant: "error",
        });
        navigate(`/${dbId}/${lang}/books/tpar-batches`);
        setLoading(false);
      },
    });
  };

  const handleSave = () => {
    setActionLoading(true);

    // Validate required fields
    if (recordData.PaymentType) {
      enqueueSnackbar("Please select Payment Type.", {
        variant: "warning",
      });
      setActionLoading(false);
      return;
    }
    if (recordData.ShowNoAbnQuestionYN === "Y" && !noAbnStatementYN) {
      enqueueSnackbar("Please select if No-ABN statement is provided.", {
        variant: "warning",
      });
      setActionLoading(false);
      return;
    }

    // Prepare the request body
    const requestBody = {
      RecordID: RecordID,
      BatchID: recordData.BatchID,
      CntID: recordData.CntID,
      Status: status,
      GrossAmt: parseInt(grossAmt) || 0,
      TaxW: parseInt(taxW) || 0,
      GstAmt: parseInt(gstAmt) || 0,
      AmendmentYN: amendmentYN,
      NoAbnStatementYN: noAbnStatementYN === "Yes" ? "Y" : "N",
      Note: notes,
      PaymentType: pmtType === "Grant" ? "G" : "P",
      GrantPmtDate:
        pmtType === "Grant" && grantPmtDate
          ? dayjs(grantPmtDate).toISOString()
          : null,
      GrantName: pmtType === "Grant" ? grantName : null,
      GrantNaneYNU:
        pmtType === "Grant"
          ? grantNaneYNU === "Yes"
            ? "Y"
            : grantNaneYNU === "No"
              ? "N"
              : "U"
          : null,
    };

    apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/accounting/tpar-save-record?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      body: requestBody,
      onSuccess: (data) => {
        if (data.SuccessYN === "Y") {
          enqueueSnackbar("Record saved successfully", { variant: "success" });
          navigate(`/${dbId}/${lang}/books/tpar-batch/${recordData.BatchID}`);
        } else {
          enqueueSnackbar(data.ErrorMessage || "Failed to save record", {
            variant: "error",
          });
        }
        setActionLoading(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage || "Error saving record.", {
          variant: "error",
        });
        setActionLoading(false);
      },
    });
  };

  const handleBack = () => {
    navigate(`/${dbId}/${lang}/books/tpar-batch/${recordData.BatchID}`);
  };

  const handleContactDetails = async () => {
    await createSessionForExternal(
      `https://${envConfig.correspondingService}/${getCookie(
        "databaseId",
      )}/en-au/contacts/contact/${recordData.CntID}`,
      "127.0.0.2",
      true,
      apiCall,
    );
  };

  const handleDelete = () => {
    setActionLoading(true);
    apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/accounting/tpar-delete-record?RecordID=${RecordID}&BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "GET",
      onSuccess: () => {
        enqueueSnackbar("Record deleted successfully", { variant: "success" });
        navigate(`/${dbId}/${lang}/books/tpar-batch/${recordData.BatchID}`);
        setActionLoading(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage || "Failed to delete record", {
          variant: "error",
        });
        setActionLoading(false);
      },
    });
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        p: 4,
        borderRadius: "8px",
        boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
      }}
    >
      <Box p={3}>
        {loading && (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {actionLoading && <LinearProgress />}
        {recordData && (
          <Box>
            {/* Headers */}
            <Typography variant="h4" gutterBottom>
              {recordData.RptTitle}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {recordData.RptSubtitle}
            </Typography>
            {recordData.RptBatchDets && (
              <Typography variant="body1" gutterBottom>
                {recordData.RptBatchDets}
              </Typography>
            )}

            {/* Action Buttons */}
            <Box sx={{ display: "flex", gap: 2, mb: 3, mt: 1 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={actionLoading}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                onClick={handleBack}
                disabled={actionLoading}
              >
                Back
              </Button>
              <Button
                variant="outlined"
                onClick={handleContactDetails}
                disabled={actionLoading}
              >
                Contact Details
              </Button>
              {/* <Button
                variant="outlined"
                onClick={handleHistory}
                disabled={actionLoading}
              >
                History
              </Button> */}
              <Button
                variant="outlined"
                color="error"
                onClick={handleDelete}
                disabled={actionLoading}
              >
                Delete
              </Button>
            </Box>

            {/* Form Fields */}
            <Grid container spacing={1} alignItems="center">
              {/* Conditional No-ABN Statement */}
              {recordData.ShowNoAbnQuestionYN === "Y" && (
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <FormControl component="fieldset" fullWidth required>
                      <Typography variant="subtitle1" gutterBottom>
                        No-ABN statement provided:
                      </Typography>
                      <RadioGroup
                        row
                        value={noAbnStatementYN}
                        onChange={(e) => setNoAbnStatementYN(e.target.value)}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              {/* Gross Amount */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Gross"
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={grossAmt}
                  onChange={(e) =>
                    setGrossAmt(e.target.value.replace(/\D/g, ""))
                  }
                  fullWidth
                  variant="outlined"
                />
              </Grid>

              {/* GST Amount */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="GST"
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={gstAmt}
                  onChange={(e) => setGstAmt(e.target.value.replace(/\D/g, ""))}
                  fullWidth
                  variant="outlined"
                />
              </Grid>

              {/* Tax Withholding */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Tax Withholding"
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={taxW}
                  onChange={(e) => setTaxW(e.target.value.replace(/\D/g, ""))}
                  fullWidth
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  <RadioGroup
                    row
                    aria-label="AmendmentYN"
                    name="AmendmentYN"
                    value={amendmentYN}
                    onChange={(e) => {
                      setAmendmentYN(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="N"
                      control={<Radio />}
                      label="Original"
                    />
                    <FormControlLabel
                      value="Y"
                      control={<Radio />}
                      label="Amendment"
                    />
                  </RadioGroup>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset" fullWidth required>
                  <RadioGroup
                    row
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <FormControlLabel
                      value="D"
                      control={<Radio />}
                      label="Draft"
                    />
                    <FormControlLabel
                      value="C"
                      control={<Radio />}
                      label="Confirmed / Ready to Lodge"
                    />
                    <FormControlLabel
                      value="N"
                      control={<Radio />}
                      label="Not to Lodge"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs>
                    <Typography variant="subtitle1">Payment Type:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <RadioGroup
                      row
                      value={pmtType}
                      required
                      onChange={(e) => setPmtType(e.target.value)}
                    >
                      <FormControlLabel
                        value="Regular payment for services"
                        control={<Radio />}
                        label="Regular payment for services"
                      />
                      <FormControlLabel
                        value="Grant"
                        control={<Radio />}
                        label="Grant"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>

              {/* Grant Fields - Only show if Payment Type is Grant */}
              {pmtType === "Grant" && (
                <>
                  {/* Grant Name */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Grant Name"
                      value={grantName}
                      onChange={(e) => setGrantName(e.target.value)}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>

                  {/* Grant Payment Date */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Grant Payment Date"
                      type="date"
                      value={grantPmtDate}
                      onChange={(e) => setGrantPmtDate(e.target.value)}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  {/* Grant is NANE */}
                  {/* Grant is NANE */}
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1">
                          Grant is NANE
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Box display="flex" alignItems="center">
                          <Tooltip title="NANE (Non-Assessable, Non-Exempt) income is income that you don't pay tax on and doesn't affect your tax losses.">
                            <IconButton size="small">
                              <InfoOutlined />
                            </IconButton>
                          </Tooltip>
                          <RadioGroup
                            row
                            value={grantNaneYNU}
                            onChange={(e) => setGrantNaneYNU(e.target.value)}
                          >
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                            <FormControlLabel
                              value="Not Sure"
                              control={<Radio />}
                              label="Not Sure"
                            />
                          </RadioGroup>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              {/* Notes */}
              <Grid item xs={12}>
                <TextField
                  label="Notes"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  multiline
                  rows={6}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default TparRecordDetails;
