import { Box, Button } from "@mui/material";
import React from "react";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { PageTitle } from "../../components/page-title";
import { ProcessTransactionsAccountSelection } from "../../features/books/process-transactions/components/process-transactions-account-selection";
import { SearchInput } from "../../components/ui/inputs/search-input";
import { useProcessTransactionsInitialData } from "../../features/books/process-transactions/api/process-transactions-inital-data";
import { useStoreAccounts } from "../../features/books/process-transactions/store/process-transactions-store-accounts";
import { useProcessTransactionsGridData } from "../../features/books/process-transactions/api/process-transcations-grid-data";
import { ProcessTransactionsDataGrid } from "../../features/books/process-transactions/components/process-transactions-data-grid";
import { useTransactions } from "../../features/books/process-transactions/store/process-transactions-store-transactions";
import { useListOfTaxCodes } from "../../features/books/tax-codes/api/list-of-tax-codes";
import { useStoreProcessTransactionsDependencies } from "../../features/books/process-transactions/store/process-transactions-store-deps";
import { useListOfSites } from "../../features/accounting/api/list-of-sites";
import { useListOfContacts } from "../../features/accounting/api/list-of-contacts";
import { ProcessTransactionsFilterPanel } from "../../features/books/process-transactions/components/process-transactions-filter-panel";
import { EventInfo } from "devextreme/events";
import dxDataGrid, { SelectionChangedInfo } from "devextreme/ui/data_grid";
import { useBuildJournal } from "../../features/books/process-transactions/api/process-transactions-build-all-journals";
import { useProcessTransactionsApplyRules } from "../../features/books/process-transactions/api/process-transactions-apply-rules-to-bank-feed";
import { enqueueSnackbar } from "notistack";
import { ProcessTransactionsQuickAddSelected } from "../../features/books/process-transactions/components/process-transactions-quick-add-selected";

export function ProcessTransactionsPage() {
  const dtGridRef = React.useRef<any>();
  const [isQuickAddOpen, setIsQuickAddOpen] = React.useState(false);

  // State/Zustand Stores
  const selectedAccount = useStoreAccounts.use.selectedAccount();
  const setSelectedAccount = useStoreAccounts.use.setSelectedAccount();

  const transactions = useTransactions.use.transactions();
  const setTransactions = useTransactions.use.setTransactions();

  const initialTransactions = useTransactions.use.initialTransactions();
  const setInitialTransactions = useTransactions.use.setInitialTransactions();

  const selectedRowsKeys = useTransactions.use.selectedRowsKeys();
  const setSelectedRowsKeys = useTransactions.use.setSelectedRowsKeys();

  const isFiltered = useTransactions.use.isFiltered();
  const setIsFiltered = useTransactions.use.setIsFiltered();
  const filteredRowsKeys = useTransactions.use.filteredRowsKeys();
  const setFilteredRowsKeys = useTransactions.use.setFilteredRowsKeys();

  const appliedRule = useTransactions.use.appliedRule();
  const setAppliedRule = useTransactions.use.setAppliedRule();

  const searchTerm = useTransactions.use.searchTerm();
  const setSearchTerm = useTransactions.use.setSearchTerm();

  // setters only for "dependencies" - one api call for all of them
  // then we used them in nested grid for "quick add" functionality
  const setListOfTaxCodes =
    useStoreProcessTransactionsDependencies.use.setListOfTaxCodes();
  const setListOfSites =
    useStoreProcessTransactionsDependencies.use.setListOfSites();
  const setListOfContacts =
    useStoreProcessTransactionsDependencies.use.setListOfContacts();

  // API calls
  const initialData = useProcessTransactionsInitialData();
  const gridDataMutation = useProcessTransactionsGridData();
  const buildJournalMutation = useBuildJournal();
  const applyRulesMutation = useProcessTransactionsApplyRules();

  const listOfTaxCodes = useListOfTaxCodes();
  const listOfSites = useListOfSites();
  const listOfContacts = useListOfContacts();

  const handleSelectionChanged = (e: EventInfo<dxDataGrid<any, any>> & any) => {
    setSelectedRowsKeys(e.selectedRowKeys);
  };

  const resetSelectionKeys = () => setSelectedRowsKeys([]);

  const handleSelectOrUnselectAllTransactions = () => {
    if (selectedRowsKeys.length === transactions.length) {
      resetSelectionKeys();
    } else {
      if (dtGridRef?.current && !!searchTerm) {
        const gridInstance = dtGridRef.current?.instance;
        const visibleRows = gridInstance.getVisibleRows();
        const filteredRowKeys = visibleRows.map((row) => row.key);
        setSelectedRowsKeys(filteredRowKeys);
        return;
      }

      setSelectedRowsKeys(transactions.map((t) => t.ID));
    }
  };

  const handleFilterWithRules = () => {
    const filteredTransactions = transactions.filter(
      (t) => t.Suggestions && t.Suggestions.length > 0,
    );
    setTransactions(filteredTransactions);
    setFilteredRowsKeys(
      filteredTransactions.map((transaction) => transaction.ID),
    );
    resetSelectionKeys();
    setIsFiltered(true);
  };

  const handleClearFilters = () => {
    resetSelectionKeys();
    setFilteredRowsKeys([]);
    setTransactions(initialTransactions);
    setSearchTerm("");
    setIsFiltered(false);
    setAppliedRule("");
  };

  const handleOnSearch = (e) => {
    if (dtGridRef?.current) {
      const gridInstance = dtGridRef.current?.instance;
      gridInstance.searchByText(e.target.value);

      const visibleRows = gridInstance.getVisibleRows();
      const filteredRowsData = visibleRows.map((row) => row.data);
      const filteredRowKeys = visibleRows.map((row) => row.key);

      setSearchTerm(e.target.value);

      console.log("Filtered Rows Data:", filteredRowsData);
      console.log("Filtered Row Keys:", filteredRowKeys);

      setFilteredRowsKeys(filteredRowKeys);
      // setTransactions(filteredRowsData);
      resetSelectionKeys();
    }
  };

  const handlePostTransactionWithRule = async () => {
    const selectedTransactions = transactions.filter((t) =>
      selectedRowsKeys.includes(t.ID),
    );

    const selectedTransactionsIDs = selectedTransactions.map((t) => t.ID);

    console.log("Selected Transactions:", selectedTransactions);
    console.log("Selected Transactions IDs:", selectedTransactionsIDs);

    if (!selectedAccount) {
      return;
    }

    const response = await buildJournalMutation.buildJournal({
      // @ts-ignore
      AccountID: selectedAccount?.ID,
      Bnk0105: "",
      RuleFilter: appliedRule,
    });

    console.log(
      "[DEBUGGING]: handlePostTransactionWithRule Response:",
      response,
    );
  };

  const handleApplyRules = async () => {
    const response = await applyRulesMutation.applyRules({
      Bnk0102: "",
    });
    console.log("[DEBUGGING]: handleApplyRules -> response", response);

    if (!selectedAccount) {
      return;
    }

    await gridDataMutation.fetchGridData({
      AccountID: selectedAccount.ID,
      Bnk0114: "",
      OffsetNo: 0,
      ReturnNo: 100,
      RuleFilter: "",
    });
  };

  const handleQuickAddSelected = () => {
    setIsQuickAddOpen(true);
  };

  // There is no better way if we will remove TanStack Query (React Query)
  // without writing a lot of additional code
  React.useEffect(() => {
    console.log("[DEBUGGING]: useEffect setting transactions called!");

    if (gridDataMutation.data?.Transactions) {
      setTransactions(gridDataMutation.data?.Transactions);
      setInitialTransactions(gridDataMutation.data?.Transactions);
    }
  }, [
    gridDataMutation.data?.Transactions,
    setTransactions,
    setInitialTransactions,
  ]);

  React.useEffect(() => {
    console.log("[DEBUGGING]: useEffect setting tax codes called!");

    if (listOfTaxCodes.data?.listOfTaxCodes) {
      setListOfTaxCodes(listOfTaxCodes.data?.listOfTaxCodes);
    }
  }, [listOfTaxCodes.data?.listOfTaxCodes, setListOfTaxCodes]);

  React.useEffect(() => {
    console.log("[DEBUGGING]: useEffect setting sites called!");
    if (listOfSites.data?.ListOfSites) {
      setListOfSites(listOfSites.data?.ListOfSites);
    }
  }, [listOfSites.data?.ListOfSites, setListOfSites]);

  React.useEffect(() => {
    console.log("[DEBUGGING]: useEffect setting contacts called!");
    if (listOfContacts.data?.ListOfContacts) {
      setListOfContacts(listOfContacts.data?.ListOfContacts);
    }
  }, [listOfContacts.data?.ListOfContacts, setListOfContacts]);

  React.useEffect(() => {
    if (initialData.error) {
      enqueueSnackbar(`Error loading initial data: ${initialData.error}`, {
        variant: "error",
      });
    }
  }, [initialData.error]);

  React.useEffect(() => {
    if (gridDataMutation.error) {
      enqueueSnackbar(`Error fetching grid data: ${gridDataMutation.error}`, {
        variant: "error",
      });
    }
  }, [gridDataMutation.error]);

  React.useEffect(() => {
    if (buildJournalMutation.error) {
      enqueueSnackbar(`Error building journal: ${buildJournalMutation.error}`, {
        variant: "error",
      });
    }
  }, [buildJournalMutation.error]);

  React.useEffect(() => {
    if (applyRulesMutation.error) {
      enqueueSnackbar(`Error applying rules: ${applyRulesMutation.error}`, {
        variant: "error",
      });
    }
  }, [applyRulesMutation.error]);

  console.log("[DEBUGGING]: transactions ->", transactions);
  console.log("[DEBUGGING]: initialTransactions ->", initialTransactions);
  console.log("[DEBUGGING]: selectedRowsKeys ->", selectedRowsKeys);

  // const isFiltered = filteredRowsKeys.length > 0 || searchTerm.length > 0;
  const areAllRowsSelected = selectedRowsKeys.length === transactions.length;
  const isFiltering = filteredRowsKeys.length > 0;
  const isSearching = searchTerm.length > 0;

  return (
    <>
      <BackdropLoading
        open={
          initialData.isLoading ||
          gridDataMutation.isLoading ||
          buildJournalMutation.isLoading ||
          applyRulesMutation.isLoading
        }
      />

      <ProcessTransactionsQuickAddSelected
        accNo={selectedAccount?.AccNo}
        isOpen={isQuickAddOpen}
        setIsOpen={setIsQuickAddOpen}
        selectedTransactions={[
          ...transactions.filter((t) => selectedRowsKeys.includes(t.ID)),
        ]}
      />

      <PageTitle title={"Account Transactions filters"} />
      <Box display={"flex"} flexDirection={"column"} gap={2} mt={4}>
        <ProcessTransactionsAccountSelection
          accounts={initialData?.data?.AccountsList || []}
          selectedAccount={selectedAccount}
          onAccountChange={(acc) => {
            // TODO: Extract this into a function
            setSelectedAccount(acc);
            gridDataMutation.fetchGridData({
              AccountID: acc.ID,
              Bnk0114: "",
              OffsetNo: 0,
              ReturnNo: 100,
              RuleFilter: "",
            });
          }}
        />
        <Box display={"flex"} gap={4}>
          <SearchInput
            value={searchTerm}
            onChange={handleOnSearch}
            sx={{ maxWidth: 400 }}
          />
          <Button
            variant="outlined"
            color={isFiltered ? "info" : "secondary"}
            disabled={isFiltered}
            onClick={handleFilterWithRules}
          >
            {isFiltered
              ? "Filtered records with Rules"
              : "Filter records with Rules"}
          </Button>
        </Box>
      </Box>

      <ProcessTransactionsFilterPanel
        isFiltering={isFiltering}
        isSearching={isSearching}
        isFiltered={isFiltered}
        isCheckboxChecked={areAllRowsSelected}
        onCheckboxChange={handleSelectOrUnselectAllTransactions}
        selectedTransactionsCount={selectedRowsKeys.length}
        onClearFilters={handleClearFilters}
        showPostTransactionWithRule={!!appliedRule}
        onPostTransactionWithRule={handlePostTransactionWithRule}
        onApplyRules={handleApplyRules}
        onQuickAddSelected={handleQuickAddSelected}
      />

      <ProcessTransactionsDataGrid
        ref={dtGridRef}
        data={transactions}
        selectedRowKeys={selectedRowsKeys}
        onSelectionChanged={handleSelectionChanged}
      />
    </>
  );
}
