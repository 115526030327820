import envConfig from "../../../../config";
import useMutation from "../../../../hooks/useMutation";

const url = `https://${envConfig.apiDev2}/api/en-au/bank-feeds/apply-rules-to-bank-feeds?BaseHostURL=${envConfig.mainServiceUrl}`;

interface ApplyRulesPayload {
  Bnk0102: string;
}

export const useProcessTransactionsApplyRules = () => {
  const { mutate, isLoading, isError, error, data } = useMutation<any>({
    url,
    method: "POST",
  });

  const applyRules = async (payload: ApplyRulesPayload) => {
    await mutate(payload);
  };

  return { applyRules, isLoading, isError, error, data };
};
