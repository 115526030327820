import React from "react";
import envConfig from "../../../../config";
import { useQuery } from "../../../../hooks/useQuery";
import { InitialData } from "../types";

const url = `https://${envConfig.apiDev2}/api/en-au/bank-feeds/get-initial-data-for-process-tr?BaseHostURL=${envConfig.mainServiceUrl}`;
// const url = `https://${envConfig.apiDev2}/api/en-au/bank-feeds/get-accounts-list-rs1?BaseHostURL=${envConfig.mainServiceUrl}`;

export const useProcessTransactionsInitialData = () => {
  const { isLoading, isError, error, data } = useQuery<InitialData>(url);

  if (process.env.NODE_ENV === "development") {
    return {
      isLoading: false,
      isError: false,
      error: null,
      data: {
        SuccessYN: "Y",
        ErrorMessage: null,
        AccountsList: [
          {
            AccID: "C3",
            AccName: "CSV Import: 1000 Cheque Account (15 to do",
          },
          {
            AccID: "F7ce30ce0-7612-4bd3-86ad-c6ed30ba9c07",
            AccName:
              "Feeds: 1000 Cheque Account  |  000-001 919 644 171 Hooli Access",
          },
          {
            AccID: "F7e850146-839d-4ba9-90bf-09c2b1c93e45",
            AccName:
              "Feeds: 1560 Fixtures acc depr  |  000-001 919 644 172 Hooli Saver",
          },
          {
            AccID: "F944d8827-cc8b-42cd-876a-6db97377aea6",
            AccName:
              "Feeds: 2030 MasterCard  |  000-001 919 644 170 Hooli Awards Card",
          },
          {
            AccID: "F5ceaf621-d2d1-432b-b407-ab24b558a5f7",
            AccName:
              "Feeds: 2610 Long-term loans  |  000-001 919 644 173 Standard Variable Rate Home Loan",
          },
        ],
        BankFeedAccNotAssigned: [],
      },
    };
  }

  return { isLoading, isError, error, data };
};
