import envConfig from "../../../config";
import { useQuery } from "../../../hooks/useQuery";
import { EXAMPLE_GET_SITES_RESPONSE } from "../../../data/example-get-sites-response";
import { useParams } from "react-router-dom";
import { getHeaders } from "../../../utils/api";
import { ResponseBoolean } from "../../../types/api/common";

const sitesUrl = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/sites/get-selection-list?activeYN=Y&BaseHostURL=${envConfig.mainServiceUrl}`;

// TODO: Move it somewhere else - probably types
export interface Site {
  dpsID: number;
  dpsName: string;
  dpsCode: string;
  dpsActiveYN: ResponseBoolean | string;
}

export interface SitesResponse {
  ListOfSites: Site[];
  errorMessage: ResponseBoolean;
  successYN: ResponseBoolean;
}

export const useListOfSites = () => {
  const { isLoading, isError, error, data, refetch } =
    useQuery<SitesResponse>(sitesUrl);

  if (process.env.NODE_ENV === "development") {
    return {
      isLoading: false,
      isError: false,
      error: null,
      data: EXAMPLE_GET_SITES_RESPONSE,
      refetch: () => {},
    };
  }

  return { isLoading, isError, error, data, refetch };
};
