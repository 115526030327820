import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Card } from "@mui/material";
import { useTheme } from "../../context/ThemeContext";
import { enqueueSnackbar } from "notistack";

import { ReactComponent as FinanceIcon } from "../../assets/reports-finance.svg";
import { ReactComponent as PayrollIcon } from "../../assets/reports-payroll.svg";
import { ReactComponent as RosterIcon } from "../../assets/reports-roster.svg";
import { ReactComponent as SalesIcon } from "../../assets/reports-sales.svg";
import { ReactComponent as TimesheetIcon } from "../../assets/reports-timesheets.svg";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import ReportsTabs from "./ReportsTabs";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";

const ReportsDashboard = () => {
  const { dbId, lang } = useParams();

  const { apiCall } = useApi();
  const prefix = `/${dbId}/${lang}/reports`;
  const [reportsData, setReportsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReports = () => {
      apiCall({
        url: `https://${envConfig.apiDev2}/api/en-au/reports/get-list-of-reports-for-dashboard?BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "GET",
        onSuccess: (data) => {
          if (data.SuccessYN === "Y") {
            // Group reports by Group field
            const groupedReports = data.Items.reduce((acc, item) => {
              acc[item.Group] = [...(acc[item.Group] || []), item];
              return acc;
            }, {});
            setReportsData(groupedReports);
            setLoading(false);
          } else {
            enqueueSnackbar(data.ErrorMessage || "Failed to fetch reports", {
              variant: "error",
            });
            setLoading(false);
          }
        },
        onError: (errorMessage) => {
          enqueueSnackbar(errorMessage || "An error occurred", {
            variant: "error",
          });
          setLoading(false); // Set loading to false on error
        },
      });
    };

    fetchReports();
  }, [apiCall]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleGroupChange = (group) => {
    if (group) {
      const newIndex = availableGroups.indexOf(group);
      if (newIndex !== -1) {
        setSelectedTab(newIndex);
      }
    }
  };

  const handleCardClick = (address) => {
    navigate(prefix + address);
  };

  const renderReportItem = (report) => {
    const getIcon = (group) => {
      const iconStyle = {
        width: 40,
        height: 40,
        stroke: theme.palette.primary.main,
        transition: "opacity 0.2s",
        opacity: 0, // Hidden by default
      };

      switch (group) {
        case "Accounting":
          return <FinanceIcon style={iconStyle} />;
        case "Payroll":
          return <PayrollIcon style={iconStyle} />;
        case "Roster":
          return <RosterIcon style={iconStyle} />;
        case "Sales":
          return <SalesIcon style={iconStyle} />;
        case "Timesheet":
          return <TimesheetIcon style={iconStyle} />;
        default:
          return <FinanceIcon style={iconStyle} />;
      }
    };

    return (
      <Grid item xs={12} sm={5.75} key={report.ID}>
        <Card
          onClick={() => handleCardClick(report.Address)}
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "24px 24px 24px 64px", // Adjusted padding
            borderRadius: "8px",
            boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
            cursor: "pointer",
            height: "100%",
            transition: "background-color 0.3s",
            "&:hover": {
              backgroundColor: "rgba(132, 159, 35, 0.04)",
              "& svg": {
                opacity: "1 !important", // Show icon on hover
              },
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: "32px", // Space between icon and text
              minWidth: "40px", // Ensure space for icon
              justifyContent: "center",
            }}
          >
            {getIcon(report.Group)}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#757575",
                fontWeight: 700,
              }}
            >
              {report.Title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {report.Description}
            </Typography>
          </Box>
        </Card>
      </Grid>
    );
  };

  if (loading) {
    return <BackdropLoading open={loading} />;
  }

  const availableGroups = Object.keys(reportsData);

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Reports Dashboard
      </Typography>

      {availableGroups.length > 0 && (
        <ReportsTabs
          availableGroups={availableGroups}
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          handleGroupChange={handleGroupChange}
        />
      )}

      <Box mt={3}>
        {availableGroups.map((group, index) => (
          <div
            key={group}
            style={{ display: selectedTab === index ? "block" : "none" }}
          >
            <Grid
              container
              sx={{
                rowGap: "16px", // 16px gap between rows
                columnGap: "20px", // 20px gap between columns
              }}
            >
              {reportsData[group].map((report) => renderReportItem(report))}
            </Grid>
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default ReportsDashboard;
