import {
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  Autocomplete,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTheme } from "../../context/ThemeContext";
import { commonThemes } from "../../utils/themes/themes";
import { useLocation } from "react-router-dom";

const GeneralJournalHeader = ({ formState, setFormState, listOfContacts }) => {
  const { isDarkMode } = useTheme();

  const {
    jnlDate,
    jnlContact_cntID,
    jnlMemo,
    showPrivatePortion,
    showVaryManualTax,
  } = formState;

  const handleDateChange = (newDate) => {
    setFormState({ ...formState, jnlDate: newDate });
  };

  const handleContactChange = (value) => {
    setFormState({ ...formState, jnlContact_cntID: value });
  };

  const handleMemoChange = (event) => {
    setFormState({ ...formState, jnlMemo: event.target.value });
  };

  const handleCheckboxChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.checked });
  };

  const filterOptions = (options, { inputValue }) => {
    const searchValue = inputValue.toLowerCase();
    return options.filter((option) => {
      const combined = `${option.cntCode} ${option.cntName}`.toLowerCase();
      return combined.includes(searchValue);
    });
  };

  return (
    <Box
      component="form"
      sx={{
        mb: 5,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 2,
        padding: "15px 15px 15px 15px",
        border: isDarkMode ? "1px solid #272E3D" : "1px solid #ddd",
        backgroundColor: isDarkMode ? "#272E3D" : "#fafafa",
        boxShadow: "0 0 30px 0 rgba(82, 63, 105, 0.05)",
        borderRadius: "4px",
        position: "relative",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <DatePicker
          label="Date"
          sx={{
            label: {
              ...commonThemes.inputLabel,
              color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
            },
          }}
          value={jnlDate}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <Autocomplete
        filterOptions={filterOptions}
        id="contacts-autocomplete"
        disabled={!listOfContacts?.length}
        options={listOfContacts || []}
        getOptionLabel={(option) => `${option.cntCode} ${option.cntName}`}
        isOptionEqualToValue={(option, value) => option.cntId === value.cntId}
        value={
          listOfContacts
            ? listOfContacts.find(
                (contact) => contact.cntId === jnlContact_cntID,
              ) || null
            : null
        }
        onChange={(_, newValue) => {
          handleContactChange(newValue ? newValue.cntId : null);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Contact"
            InputLabelProps={{ shrink: true }}
            sx={{
              ".MuiInputBase-root": {
                borderRadius: "8px",
                paddingTop: "8px !important",
                paddingBottom: "9px !important",
              },
              label: {
                ...commonThemes.inputLabel,
                color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
              },
              minWidth: "280px",
              maxWidth: "280px",
              marginRight: 2,
            }}
          />
        )}
      />

      <TextField
        label="Transaction Memo"
        multiline
        rows={3}
        value={jnlMemo}
        InputLabelProps={{ shrink: !!jnlMemo }}
        onChange={handleMemoChange}
        sx={{
          label: {
            ...commonThemes.inputLabel,
            color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
          },
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
        <Typography sx={{ fontSize: 14 }} variant="p">
          Show / Hide Columns
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showPrivatePortion}
                onChange={handleCheckboxChange}
                name="showPrivatePortion"
              />
            }
            label="Private Portion"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showVaryManualTax}
                onChange={handleCheckboxChange}
                name="showVaryManualTax"
              />
            }
            label="Vary / Manual Tax"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralJournalHeader;
