import envConfig from "../../../../config";
import useMutation from "../../../../hooks/useMutation";

const url = `https://${envConfig.apiDev2}/api/en-au/bank-feeds/build-all-journals-with-matched-feed?BaseHostURL=${envConfig.mainServiceUrl}`;

interface BuildJournalPayload {
  Bnk0105: string;
  AccountID: string;
  RuleFilter: string;
}

export const useBuildJournal = () => {
  const { mutate, isLoading, isError, error, data } = useMutation<any>({
    url,
    method: "POST",
  });

  const buildJournal = async (payload: BuildJournalPayload) => {
    await mutate(payload);
  };

  return { buildJournal, isLoading, isError, error, data };
};
