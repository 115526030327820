import envConfig from "../../../../config";
import useMutation from "../../../../hooks/useMutation";

const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/bank-journal/create-bulk-bank-journal-transactions-async?BaseHostURL=${envConfig.mainServiceUrl}`;

interface CreateBankJournalPayload {
  ListOfTransactions: string[];
  AccountNo: number | string;
  TaxCodeId: number | string;
  Description: string;
  ContactId: number | string;
  SiteId: number | string;
  ReviewYN: string;
  PrivPc: number | string;
}

interface CreateBankJournalResponse {
  SuccessYN: string;
  ErrorMessage: string;
  ListOfBankJournalsFromBankFeedsTrans: number[];
  ListOfBankJournalFromCSVTrans: number[];
}

export const useCreateBankJournalTransactions = () => {
  const { mutate, isLoading, isError, error, data } =
    useMutation<CreateBankJournalResponse>({
      url,
      method: "POST",
    });

  const createBankJournalTransactions = async (
    payload: CreateBankJournalPayload,
  ) => {
    await mutate(payload);
  };

  return { createBankJournalTransactions, isLoading, isError, error, data };
};
