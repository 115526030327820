import React, { createContext, useEffect } from "react";
import { useLoginPopup } from "./LoginPopupContext";
import { getCookie } from "../utils/api";
import envConfig from "../config";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { handleOpen } = useLoginPopup();

  const checkAuth = () => {
    if (process.env.NODE_ENV === "development") {
      return;
    }

    const storedTime = getCookie("apiKeyExpirationUTCDate");

    if (!storedTime) {
      handleOpen();
      return;
    }

    const currentTimeUTC = new Date().toUTCString();
    const storedTimeUTC = new Date(storedTime + "Z").toUTCString();
    const difference =
      new Date(storedTimeUTC).getTime() - new Date(currentTimeUTC).getTime();

    const maxDiff = envConfig.loginUrl.includes(".app") ? 1 : 5; // in minutes
    if (difference <= maxDiff * 60 * 1000) {
      handleOpen();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkAuth();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
