import React from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import { CustomReportsCheckbox } from "./custom-reports-checkbox";
import { CustomReportsUsers } from "./custom-reports-users";
import { CustomReportsSingleSelection } from "./custom-reports-single-selection";
import { CustomReportsTextField } from "./custom-reports-textfield";
import { CustomReportsIntegerField } from "./custom-reports-integerfield";
import { CustomReportsDate } from "./custom-reports-date";
import {
  DatePredefinedEndDatePicker,
  DatePredefinedPeriodPicker,
  PeriodType,
} from "../../../../components/ui/date-predefined-period-picker";
import {
  EndDatePeriodType,
  getEndDatePeriodDate,
  getPredefinedDates,
} from "../../../../utils/format-date";
import { CustomReportParameter } from "../types";

interface CustomReportsFiltersPanelProps {
  state: any;
  isFieldValid: any;
  handleOnChange: any;
  parameters: CustomReportParameter[];
  onApplyFilters: () => void;
  addDateSelector: "P" | "E" | undefined;
}

export const CustomReportsFiltersPanel = ({
  state,
  isFieldValid,
  handleOnChange,
  parameters,
  onApplyFilters,
  addDateSelector,
}: CustomReportsFiltersPanelProps) => {
  const isMobile = useMediaQuery("(max-width: 580px)");

  const isFirstParamDate = Boolean(parameters?.[0]?.ControlType === "date");
  const isSecondParamDate = Boolean(parameters?.[1]?.ControlType === "date");

  const startDateLabel =
    addDateSelector === "P" && isFirstParamDate
      ? `p${parameters[0].Order}`
      : undefined;
  const endDateLabel =
    addDateSelector === "P" && isSecondParamDate
      ? `p${parameters[1].Order}`
      : addDateSelector === "E" && isFirstParamDate
        ? `p${parameters[0].Order}`
        : undefined;

  const handlePredefinedPeriodChange = (periodType: PeriodType) => {
    handleOnChange("predefinedPeriod", periodType);
    const predefinedDates = getPredefinedDates(periodType);
    if (predefinedDates) {
      handleOnChange(startDateLabel, predefinedDates[0]);
      handleOnChange(endDateLabel, predefinedDates[1]);
    }
  };

  const handlePredefinedEndDateChange = (endDatePeriod: EndDatePeriodType) => {
    handleOnChange("predefinedPeriod", endDatePeriod);
    const predefinedEndDate = getEndDatePeriodDate(endDatePeriod);
    if (predefinedEndDate) {
      handleOnChange(endDateLabel, predefinedEndDate);
    }
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      marginBottom={1}
      padding={3}
      // border={isDarkMode ? `1px solid #272E3D` : "1px solid #ddd"}
      // boxShadow={"0 0 30px 0 rgba(82, 63, 105, 0.05)"}
      boxShadow="0px 0px 10px 0px rgba(69, 90, 100, 0.10)"
      borderRadius={1}
    >
      <Box
        flex={1}
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "20px",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        {addDateSelector === "E" && (isFirstParamDate || isSecondParamDate) && (
          <Box
            sx={{ width: "100%", maxWidth: "260px" }}
            display={"flex"}
            alignItems={"center"}
            gap={2}
          >
            <DatePredefinedEndDatePicker
              value={state?.predefinedPeriod?.value}
              onChange={handlePredefinedEndDateChange}
              sx={{
                width: "100%",
                // height: "40px",
                "& .MuiInputBase-input": {
                  fontSize: "16px",
                  lineHeight: "22px",
                },
              }}
            />
            <Box>Date</Box>
          </Box>
        )}
        {addDateSelector === "P" && (isFirstParamDate || isSecondParamDate) && (
          <Box
            sx={{ width: "100%", maxWidth: "260px" }}
            display={"flex"}
            alignItems={"center"}
            gap={2}
          >
            <DatePredefinedPeriodPicker
              value={state?.predefinedPeriod?.value}
              onChange={handlePredefinedPeriodChange}
              sx={{
                width: "100%",
                // height: "40px",
                "& .MuiInputBase-input": {
                  fontSize: "16px",
                  lineHeight: "22px",
                },
              }}
            />
            <Box>OR</Box>
          </Box>
        )}
        {parameters.map((parameter) => {
          if (parameter.ControlType === "checkbox") {
            return (
              <CustomReportsCheckbox
                value={state?.[`p${parameter.Order}`]?.value}
                handleOnChange={handleOnChange}
                key={`p${parameter.Order}`}
                parameter={parameter}
              />
            );
          } else if (parameter.ControlType === "users") {
            return (
              <CustomReportsUsers
                value={state?.[`p${parameter.Order}`]?.value || []}
                handleOnChange={handleOnChange}
                key={`p${parameter.Order}`}
                parameter={parameter}
              />
            );
          } else if (parameter.ControlType === "single-selection") {
            return (
              <CustomReportsSingleSelection
                value={state?.[`p${parameter.Order}`]?.value}
                handleOnChange={handleOnChange}
                key={`p${parameter.Order}`}
                parameter={parameter}
                isFieldValid={isFieldValid[`p${parameter.Order}`]?.isValid}
                errorMessage={isFieldValid[`p${parameter.Order}`]?.errorMessage}
              />
            );
          } else if (parameter.ControlType === "text") {
            return (
              <CustomReportsTextField
                value={state?.[`p${parameter.Order}`]?.value}
                handleOnChange={handleOnChange}
                key={`p${parameter.Order}`}
                parameter={parameter}
                isFieldValid={isFieldValid[`p${parameter.Order}`]?.isValid}
                errorMessage={isFieldValid[`p${parameter.Order}`]?.errorMessage}
              />
            );
          } else if (parameter.ControlType === "integer") {
            return (
              <CustomReportsIntegerField
                value={state?.[`p${parameter.Order}`]?.value}
                handleOnChange={handleOnChange}
                key={`p${parameter.Order}`}
                parameter={parameter}
                isFieldValid={isFieldValid[`p${parameter.Order}`]?.isValid}
                errorMessage={isFieldValid[`p${parameter.Order}`]?.errorMessage}
              />
            );
          } else if (parameter.ControlType === "date") {
            return (
              <CustomReportsDate
                value={state?.[`p${parameter.Order}`]?.value}
                handleOnChange={handleOnChange}
                key={`p${parameter.Order}`}
                parameter={parameter}
                isFieldValid={isFieldValid[`p${parameter.Order}`]?.isValid}
                errorMessage={isFieldValid[`p${parameter.Order}`]?.errorMessage}
              />
            );
          } else {
            return <>"{parameter.ControlType}" control not imlemented</>;
          }
        })}
      </Box>
    </Box>
  );
};
