import React, { createContext, useState, useContext } from "react";
import LoginPopup from "../components/LoginPopup/LoginPopup";
import { useSnackbar } from "notistack";

const PopupContext = createContext();

export function PopupProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);
  const [resolveReAuth, setResolveReAuth] = useState(null);

  const handleOpen = () => {
    if (process.env.NODE_ENV === "development") {
      return;
    }
    if (isCooldown) return;
    setOpen(true);

    // Return a promise that resolves when re-authentication is complete
    return new Promise((resolve) => {
      setResolveReAuth(() => resolve);
    });
  };

  const handleClose = () => {
    setOpen(false);
    setIsCooldown(true);
    setTimeout(() => {
      setIsCooldown(false);
    }, 5000);
  };

  const onReAuthSuccess = () => {
    if (resolveReAuth) {
      resolveReAuth(); // Resolve the promise to continue API call
      setResolveReAuth(null);
    }
    handleClose();
  };

  const waitForReAuth = () => {
    // If re-auth is already in progress, return the existing promise
    if (resolveReAuth) {
      return new Promise((resolve) => {
        const currentResolve = resolveReAuth;
        setResolveReAuth(() => {
          currentResolve();
          resolve();
        });
      });
    } else {
      // Otherwise, return a resolved promise
      return Promise.resolve();
    }
  };

  return (
    <PopupContext.Provider
      value={{ open, handleOpen, handleClose, waitForReAuth, onReAuthSuccess }}
    >
      {children}
      <LoginPopup
        open={open}
        onReAuthSuccess={onReAuthSuccess}
        handleClose={handleClose}
      />
    </PopupContext.Provider>
  );
}

export function useLoginPopup() {
  return useContext(PopupContext);
}
