import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import {
  clearAllCookies,
  getCookie,
  getLoginSecured,
  logoutUser,
} from "../../utils/api";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import { useSnackbar } from "notistack";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import envConfig from "../../config";
import useApi from "../../hooks/useApi";
import { useGetIP } from "../../hooks/get-ip";
import { useLoginPopup } from "../../context/LoginPopupContext";

const LoginPopup = ({ open, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { onReAuthSuccess } = useLoginPopup();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [remember, setRemember] = useState(true);
  const [loginEmail, setLoginEmail] = useState();
  const [smsSent, setSmsSent] = useState();

  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const { apiCall } = useApi();
  const ip = useGetIP();

  const handleLogin = async () => {
    // TODO: IMPORTANT: Remove this line in final version
    if (process.env.NODE_ENV === "development") {
      return;
    }

    const storedTime = getCookie("apiKeyExpirationUTCDate");

    // if (!storedTime) {
    // window.location = `https://${envConfig.loginUrl}/?redirectBackTo=${window.location.href}`;
    // return;
    // }

    const currentTimeUTC = new Date().toUTCString();
    const storedTimeUTC = new Date(storedTime + "Z").toUTCString();
    const difference =
      new Date(storedTimeUTC).getTime() - new Date(currentTimeUTC).getTime();

    const maxDiff = envConfig.loginUrl.includes(".app") ? 1 : 5;
    if (difference < maxDiff * 60 * 1000) {
      await generateNewApiKeyAndValidate2FA();
    } else {
      const url = `https://${envConfig.apiLogin}/api/v1/en-au/system/extend-api-key-by-reentering-password-with-2fa?BaseHostURL=${envConfig.mainServiceUrl}`;
      const body = {
        LoginSecuredHash: getLoginSecured(email, password),
        UserLogin2FACode: code,
        RememberMeYN: remember ? "Y" : "N",
      };

      await apiCall({
        url,
        ip: ip,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "olivs-api-real-ip": "115.70.37.103",
          "olivs-api-computer-name": "BTM-WS2019",
          "session-id": getCookie("sessionId"),
          "browser-agent": window.navigator.userAgent,
          "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
          "api-key": getCookie("apiKey") + "-0",
        },
        body,
        skipAuth: true,
        onSuccess: async (data) => {
          console.log("succ");
          console.log(data);
          Cookies.set("apiKey", data.ApiKey, { path: "/" });
          Cookies.set(
            "apiKeyExpirationUTCDate",
            data.ApiKeyExpiryUTC.slice(0, -1),
            {
              path: "/",
            },
          );
          Cookies.set("sessionId", data.SessionID, { path: "/" });
          Cookies.set("firstName", data.FirstName, { path: "/" });
          Cookies.set("lastName", data.LastName, { path: "/" });
          setPassword("");
          setCode("");
          handleClose();
          await onReAuthSuccess();
        },
        onError: (errorMessage) => {
          if (
            errorMessage === "Your session has expired, please log back in." ||
            errorMessage === "Your session expired. Please log in again." ||
            errorMessage.includes("API")
          ) {
            generateNewApiKeyAndValidate2FA();
          } else {
            if (errorMessage) {
              enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
              clearAllCookies();
              window.location = `https://${envConfig.loginUrl}?redirectBackTo=${window.location.href}`;
            }
          }
        },
      });
    }
  };

  const generateNewApiKeyAndValidate2FA = async () => {
    const generateTokenUrl = `https://${envConfig.apiLogin}/api/v1/en-au/system/generate-new-api-token-details?BaseHostURL=${envConfig.loginUrl}`;
    const loginSecured = getLoginSecured(email, password);
    const generateTokenData = {
      LoginSecuredHash: loginSecured,
    };

    await apiCall({
      url: generateTokenUrl,
      method: "POST",
      skipAuth: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "olivs-api-real-ip": "127.0.0.2",
        "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
        "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
        "browser-agent": window.navigator.userAgent,
        "api-key": "OlivsAuth",
      },
      body: generateTokenData,
      onSuccess: async (generateTokenJson) => {
        if (generateTokenJson?.ApiKey) {
          const verificationUrl = `https://${envConfig.apiLogin}/api/v1/en-au/system/validate-2fa-code?BaseHostURL=${envConfig.loginUrl}`;
          const verificationData = {
            UserLogin2FACode: code,
            RememberMeYN: remember ? "Y" : "N",
          };

          await apiCall({
            url: verificationUrl,
            skipAuth: true,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "olivs-api-real-ip": ip,
              "olivs-api-computer-name": "BTM-WS2019",
              "session-id": generateTokenJson.SessionID,
              "browser-agent": window.navigator.userAgent,
              "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
              "api-key": generateTokenJson.ApiKey + "-0",
            },
            body: verificationData,
            onSuccess: async (verificationJson) => {
              if (verificationJson.SuccessYN === "Y") {
                Cookies.set("apiKey", generateTokenJson.ApiKey, {
                  path: "/",
                });
                Cookies.set(
                  "apiKeyExpirationUTCDate",
                  verificationJson.ApiKeyExpiryUTC.slice(0, -1),
                  {
                    path: "/",
                  },
                );
                Cookies.set("sessionId", generateTokenJson.SessionID, {
                  path: "/",
                });
                Cookies.set("firstName", generateTokenJson.firstName, {
                  path: "/",
                });
                Cookies.set("lastName", generateTokenJson.lastName, {
                  path: "/",
                });
                setPassword("");
                setCode("");
                await onReAuthSuccess();
                handleClose();
              } else {
                enqueueSnackbar(verificationJson.ErrorMessage, {
                  variant: "error",
                });
              }
            },
            onError: (errorMessage) => {
              enqueueSnackbar(errorMessage, { variant: "error" });
            },
          });
        }
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
      },
    });
  };

  const sendCodeViaSMS = async () => {
    setSmsSent(true);
    const smsUrl = `https://${envConfig.apiDev1}/api/v3/en-au/user2fa/send-code-as-sms?BaseHostURL=${envConfig.mainServiceUrl}`;

    await apiCall({
      url: smsUrl,
      ip: ip,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "olivs-api-real-ip": ip,
        "olivs-api-computer-name": "BTM-WS2019",
        "session-id": getCookie("sessionId"),
        "browser-agent": window.navigator.userAgent,
        "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
        "api-key": getCookie("apiKey") + "-0",
      },
      onSuccess: (smsJson) => {
        enqueueSnackbar("SMS sent successfully", { variant: "success" });
      },
      onError: (errorMessage) => {
        setSmsSent(false);
        enqueueSnackbar(errorMessage, { variant: "error" });
      },
    });
  };

  useEffect(() => {
    const email = getCookie("loginEmail");
    setEmail(email);
    setLoginEmail(email);
  }, [open]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "r") {
        setRemember((prevRemember) => !prevRemember);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div
      style={{
        zIndex: 9999999999999,
      }}
    >
      <Dialog
        sx={{
          zIndex: "9999999999999 !important",
          "& .MuiDialog-paper": {
            backdropFilter: "blur(3px)",
          },
        }}
        open={open}
        onClose={(e, reason) => {
          return;
          // clearAllCookies();
          if (reason && reason === "backdropClick") return;
          window.location = `https://${envConfig.loginUrl}?redirectBackTo=${window.location.href}`;
        }}
      >
        <DialogTitle>Log in again</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={email}
            disabled={email === loginEmail && loginEmail !== "" && loginEmail}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              mt: 2,
              maxWidth: "480px",
              display: "block",
              label: { fontSize: 16, marginTop: 0 },
              "p.Mui-error": {
                position: "absolute",
              },
            }}
            size="small"
          />
          <TextField
            label="Password"
            type="password"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            size="small"
            sx={{
              mt: 2,
              maxWidth: "480px",
              display: "block",
              label: { fontSize: 16, marginTop: 0 },
              "p.Mui-error": {
                position: "absolute",
              },
            }}
          />
          <TextField
            label="2FA Code"
            type="tel"
            sx={{
              width: "100%",
              "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                {
                  WebkitAppearance: "none",
                  margin: 0,
                },

              "input[type=number]": {
                MozAppearance: "textfield",
              },
              label: { fontSize: 16, marginTop: 0 },
              mt: 2,
              maxWidth: "480px",
              display: "block",
              "p.Mui-error": {
                position: "absolute",
              },
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && code?.length === 6 && password) {
                handleLogin();
              }
            }}
            InputLabelProps={{ shrink: true }}
            fullWidth
            size="small"
            variant="outlined"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Link
            className={"link " + (smsSent ? "disabled" : "")}
            onClick={() => {
              if (!smsSent) sendCodeViaSMS();
            }}
            aria-disabled={smsSent}
            style={{
              color: smsSent ? "#888888" : isDarkMode ? "#AAD269" : "#849F23",
              marginTop: 0,
              cursor: smsSent ? "default" : "pointer",
              fontSize: "14px",
              fontWeight: 700,
              letterSpacing: "0.4px",
              textTransform: "capitalize",
              textDecoration: "none",
            }}
          >
            Send code via SMS
          </Link>
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={remember}
                onChange={(e) => setRemember(e.target.checked)}
              />
            }
            label={
              <span>
                <u>R</u>emember Me
              </span>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="secondary"
            onClick={async () => {
              await logoutUser();
              window.location = `https://${envConfig.loginUrl}?redirectBackTo=${window.location.href}`;
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={code?.length !== 6 || !password}
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: theme.palette.primary.main,
              },
            }}
            variant="primary"
            onClick={handleLogin}
          >
            Log In
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoginPopup;
