import { create } from "zustand";
import { createSelectors } from "../../../../utils/createSelectors";
import { Account } from "../types";

interface State {
  selectedAccount: Account | null;
}

interface Actions {
  setSelectedAccount: (account: Account) => void;
}

export const useStoreAccountsBase = create<State & Actions>()((set) => ({
  selectedAccount: null,
  setSelectedAccount: (account) => set({ selectedAccount: account }),
}));

export const useStoreAccounts = createSelectors(useStoreAccountsBase);
