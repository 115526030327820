import envConfig from "../../../../config";
import useMutation from "../../../../hooks/useMutation";
import {
  GetUnprocessedTransactionsRS1Response,
  UnprocessedTransactions,
} from "../types";
import { EXAMPLE_GET_UNPROCESSED_TRANSACTIONS_RS1_RESPONSE } from "./example-response";

const url = `https://${envConfig.apiDev2}/api/en-au/bank-feeds/get-unprocessed-transactions-rs1?BaseHostURL=${envConfig.mainServiceUrl}`;

interface ProcessTransactionsPayload {
  Bnk0114: string;
  AccountID: string;
  OffsetNo: number;
  ReturnNo: number;
  RuleFilter: string;
}

export const useProcessTransactionsGridData = () => {
  const { mutate, isLoading, isError, error, data } = useMutation<any>({
    url,
    method: "POST",
  });

  const fetchGridData = async (payload: ProcessTransactionsPayload) => {
    await mutate(payload);
  };

  if (process.env.NODE_ENV === "development") {
    return {
      fetchGridData,
      isLoading,
      isError,
      error,
      data: EXAMPLE_GET_UNPROCESSED_TRANSACTIONS_RS1_RESPONSE as unknown as GetUnprocessedTransactionsRS1Response,
    };
  }

  return { fetchGridData, isLoading, isError, error, data };
};
