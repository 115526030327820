import { Box, Tabs, Tab, Typography } from "@mui/material";
import { ReactComponent as FinanceIcon } from "../../assets/reports-finance.svg"; // Example icons
import { ReactComponent as PayrollIcon } from "../../assets/reports-payroll.svg";
import { ReactComponent as RosterIcon } from "../../assets/reports-roster.svg";
import { ReactComponent as SalesIcon } from "../../assets/reports-sales.svg";
import { ReactComponent as TimesheetIcon } from "../../assets/reports-timesheets.svg";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";

// Helper function to get the correct icon
const getTabIcon = (group, isSelected, isDarkMode) => {
  const iconStyle = {
    marginRight: "8px",
    width: "24px",
    height: "24px",
    stroke: isSelected ? "#849F23" : isDarkMode ? "#E8E8E8" : "#252525",
  };
  switch (group) {
    case "Accounting":
      return <FinanceIcon style={iconStyle} />;
    case "Payroll":
      return <PayrollIcon style={iconStyle} />;
    case "Roster":
      return <RosterIcon style={iconStyle} />;
    case "Sales":
      return <SalesIcon style={iconStyle} />;
    case "Timesheet":
      return <TimesheetIcon style={iconStyle} />;
    default:
      return;
  }
};

// CSS Keyframes for the border animation
const borderExpand = {
  "@keyframes expand": {
    "0%": { width: "0%", left: "50%" },
    "100%": { width: "100%", left: "0%" },
  },
};

const ReportsTabs = ({ availableGroups, selectedTab, handleTabChange }) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <Tabs
      value={selectedTab}
      onChange={handleTabChange}
      aria-label="report tabs"
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
      TabIndicatorProps={{
        style: {
          backgroundColor: "transparent", // Remove default indicator
        },
      }}
    >
      {availableGroups.map((group, index) => {
        const isSelected = selectedTab === index;

        return (
          <Tab
            disableRipple
            key={group}
            value={index}
            label={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  py: 1,
                  position: "relative",
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: "50%",
                    width: "0%",
                    height: "2px",
                    backgroundColor: isSelected
                      ? theme.palette.primary.main
                      : "transparent",
                    animation: isSelected ? "expand 0.4s forwards" : "none",
                  },
                  ...borderExpand, // Applying the animation keyframes
                }}
              >
                {getTabIcon(group, isSelected, isDarkMode)}
                <Typography
                  sx={{
                    color: isSelected
                      ? theme.palette.primary.main
                      : theme.palette.blackWhite.main,
                    fontSize: {
                      xs: "18px", // For small screens
                      sm: "20px", // Slightly bigger on larger mobile screens
                      md: "24px", // Default size for tablets and larger screens
                    },
                    fontWeight: "400",
                    leadingTrim: "both",
                    textEdge: "cap",
                  }}
                >
                  {group}
                </Typography>
              </Box>
            }
            sx={{
              minWidth: "auto", // Ensures the tabs don't stretch too much
              padding: {
                xs: "8px 16px", // Smaller padding on mobile
                sm: "10px 20px", // Slightly larger on small screens
                md: "12px 24px", // Default padding on larger screens
              },
              textTransform: "none", // Keep the text as-is
            }}
          />
        );
      })}
    </Tabs>
  );
};

export default ReportsTabs;
