import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { DxElement } from "devextreme/core/element";
import {
  UnprocessedTransactions,
  UnprocessedTransactionsSuggestion,
} from "../types";
import { commonThemes } from "../../../../utils/themes/themes";
import { formatDate } from "../../../../utils/format-date";
import { formatFinancialNumber } from "../../../../utils/format-number";
import { UnprocessedTransactionsTableSuggestions } from "./process-transactions-table-row-suggestions";
import {
  EntryRow,
  UnprocessedTransactionsTableRowEntry,
} from "./process-transactions-table-row-entry";
import { useListOfTaxCodes } from "../../tax-codes/api/list-of-tax-codes";
import { useStoreProcessTransactionsDependencies } from "../store/process-transactions-store-deps";
import { useTheme } from "../../../../context/ThemeContext";
import { useTransactions } from "../store/process-transactions-store-transactions";
import { useNavigate, useParams } from "react-router-dom";
import envConfig from "../../../../config";
import { createSessionForExternal } from "../../../../utils/reusable";
import useApi from "../../../../hooks/useApi";
import { useCreateBankJournalTransactions } from "../api/process-transactions-create-bank-journal-transactions";
import { enqueueSnackbar } from "notistack";

interface UnprocessedTransactionsTableRowProps {
  data: UnprocessedTransactions; // the data object for the current row
  rowIndex: number; // the index of the row
  component: any; // the DataGrid component instance
  isSelected?: boolean; // whether the row is selected
  isEditing?: boolean; // whether the row is in editing mode
  rowType: string; // the type of the row (e.g., 'data', 'group', 'header')
  values: any[]; // an array of the values for the row's cells
  rowElement: DxElement; // the actual DOM element for the row
  key: any; // the key of the row
  groupIndex?: number; // the index of the group (if applicable)

  selectedRowKeys: string[]; // an array of the keys of the selected rows
  onSelectionChanged: (e: any) => void;
}

export const UnprocessedTransactionsTableRow = ({
  data,
  rowIndex,
  component,
  isSelected,
  rowElement,
  selectedRowKeys = [],
  onSelectionChanged,
}: UnprocessedTransactionsTableRowProps) => {
  const { isDarkMode } = useTheme();
  const { dbId: dbFromParam } = useParams();
  const { apiCall } = useApi();

  const navigate = useNavigate();

  const [showQuickAddMenu, setShowQuickAddMenu] = React.useState(false);

  const createBankJournalTransactions = useCreateBankJournalTransactions();

  const appliedRule = useTransactions.use.appliedRule();
  const setAppliedRule = useTransactions.use.setAppliedRule();

  const transactions = useTransactions.use.transactions();
  const setTransactions = useTransactions.use.setTransactions();

  const setSelectedRowsKeys = useTransactions.use.setSelectedRowsKeys();
  const setFilteredRowsKeys = useTransactions.use.setFilteredRowsKeys();

  const listOfTaxCodes =
    useStoreProcessTransactionsDependencies.use.listOfTaxCodes();
  const listOfAllSites =
    useStoreProcessTransactionsDependencies.use.listOfSites();
  const listOfContacts =
    useStoreProcessTransactionsDependencies.use.listOfContacts();

  const handleQuickAddClick = () => setShowQuickAddMenu(!showQuickAddMenu);

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedSelectedKeys = event.target.checked
      ? [...selectedRowKeys, data.ID]
      : selectedRowKeys.filter((key) => key !== data.ID);

    onSelectionChanged({ selectedRowKeys: updatedSelectedKeys });
  };

  const handleFindAllWithRule = (
    suggestion: UnprocessedTransactionsSuggestion,
  ) => {
    console.log("Find all with rule - suggestion: ", suggestion);
    const matchCode = suggestion.MatchCode;

    const filteredTransactions = transactions.filter((transaction) => {
      const transactionSuggestions = transaction.Suggestions;
      return transactionSuggestions.some(
        (suggestion) => suggestion.MatchCode === matchCode,
      );
    });

    console.log("Filtered transactions: ", filteredTransactions);

    setTransactions(filteredTransactions);
    setSelectedRowsKeys(filteredTransactions.map((t) => t.ID));
    setFilteredRowsKeys(filteredTransactions.map((t) => t.ID));
    setAppliedRule(matchCode);
  };

  const handleApplySuggestion = (
    suggestion: UnprocessedTransactionsSuggestion,
  ) => {
    console.log("Apply suggestion: ", suggestion);
  };

  const onNewJournal = () => {
    navigate(`../${dbFromParam}/en-au/books/bank-journal?feedId=${data.ID}`);
  };

  const onNewPayment = () => {
    const newUrl = `https://${envConfig.correspondingService}/payments/${data.Amount < 0 ? "bill" : "invoice"}/0-0-0/${data.ID}?accountno=${data.AccNo}`;
    createSessionForExternal(newUrl, "", true, apiCall);
  };

  const onSaveAndPostEntry = async (args) => {
    await createBankJournalTransactions.createBankJournalTransactions(args);
    setShowQuickAddMenu(false);
  };

  const initialEntryData: EntryRow[] = [
    {
      accNo: data.AccNo,
      taxId: undefined,
      memo: "",
      contact: "",
      review: false,
      site: "",
    },
  ];

  React.useEffect(() => {
    if (
      createBankJournalTransactions.isError ||
      createBankJournalTransactions.error
    ) {
      enqueueSnackbar(
        `Error adding transactions: ${createBankJournalTransactions.error}`,
        {
          variant: "error",
        },
      );
    }
  }, [createBankJournalTransactions]);

  // console.log("[DEBUGGING ROW]: selectedRowsKeys: ", selectedRowKeys);

  return (
    <tr key={data.ID}>
      <td colSpan={6} style={{ padding: 0, margin: 0 }}>
        <Box
          width={"100%"}
          borderBottom={isDarkMode ? "1px solid #3e424d" : "1px solid #e0e0e0"}
        >
          <Box display="flex" alignItems={"top"} gap={4} p={2}>
            <Box display="flex" alignItems={"center"} height={"36px"}>
              <UnprocessedTransactionsTableRowSelector
                value=""
                checked={selectedRowKeys.includes(data.ID)}
                onChange={handleSelectChange}
                label=""
              />
              <Typography>{formatDate(data.TransactionDate)}</Typography>
            </Box>
            <Box display="flex" flexDirection={"column"} gap={2} flex="1">
              <Typography fontSize={24} fontWeight={400} component="h4">
                {data.Description}
              </Typography>
              <UnprocessedTransactionsTableRowAmount amount={data.Amount} />
              <UnprocessedTransactionsTableRowActions
                disableQuickAdd={showQuickAddMenu}
                onQuickAddClick={handleQuickAddClick}
                onNewJournal={onNewJournal}
                onNewPayment={onNewPayment}
              />
              <UnprocessedTransactionsTableSuggestions
                suggestions={data.Suggestions}
                isDarkMode={isDarkMode}
                onApplySuggestion={handleApplySuggestion}
                onFindAllWithRule={handleFindAllWithRule}
              />
            </Box>
          </Box>
          {showQuickAddMenu && (
            <UnprocessedTransactionsTableRowEntry
              isLoading={createBankJournalTransactions.isLoading}
              initialData={initialEntryData}
              listOfTaxCodes={listOfTaxCodes || []}
              listOfSites={listOfAllSites || []}
              listOfContacts={listOfContacts || []}
              onClose={() => setShowQuickAddMenu(false)}
              onSaveAndPostEntry={onSaveAndPostEntry}
              hideMemo={false}
            />
          )}
        </Box>
      </td>
    </tr>
  );
};

interface UnprocessedTransactionsTableRowSelectorProps {
  value: string;
  checked: boolean;
  onChange:
    | ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void)
    | undefined;
  label: string;
}

export const UnprocessedTransactionsTableRowSelector = ({
  value,
  checked,
  onChange,
  label,
}: UnprocessedTransactionsTableRowSelectorProps) => {
  return (
    <FormControlLabel
      key={""}
      value={value}
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          id="showAccountsNumbers"
        />
      }
      label={label}
      sx={{
        display: "block",
        mr: 2,
        ml: 0,
        // @ts-ignore
        span: { ...commonThemes.normalText },
        "& .MuiCheckbox-root": {
          padding: 0,
        },
      }}
    />
  );
};

export const UnprocessedTransactionsTableRowAmount = ({ amount }) => {
  const isPositive = amount >= 0;
  const label = isPositive ? "Received: " : "Spent: ";
  const value = isPositive ? amount : Math.abs(amount);
  const color = isPositive ? "green" : "red";

  return (
    <Typography
      sx={{
        fontStyle: "italic",
        fontWeight: "bold",
      }}
    >
      <span>{label}</span>
      <span style={{ color: color }}>{formatFinancialNumber(value)}</span>
    </Typography>
  );
};

interface UnprocessedTransactionsTableRowActionsProps {
  onQuickAddClick: () => void;
  onNewJournal?: () => void;
  disableQuickAdd: boolean;
  onNewPayment?: () => void;
}

export const UnprocessedTransactionsTableRowActions = ({
  onQuickAddClick,
  disableQuickAdd,
  onNewJournal,
  onNewPayment,
}: UnprocessedTransactionsTableRowActionsProps) => {
  return (
    <Box display="flex" alignItems={"center"} gap={2}>
      <Button
        // @ts-ignore
        variant="primary"
        onClick={onQuickAddClick}
        sx={{ fontWeight: 700 }}
        disabled={disableQuickAdd}
      >
        Quick Add
      </Button>
      <Button
        // @ts-ignore
        variant="secondary"
        sx={{ fontWeight: 700 }}
        onClick={onNewJournal}
      >
        New Journal
      </Button>
      <Button
        // @ts-ignore
        variant="secondary"
        onClick={onNewPayment}
        sx={{ fontWeight: 700 }}
      >
        New Payment
      </Button>
      <Button
        // @ts-ignore
        variant="secondary"
        onClick={onQuickAddClick}
        sx={{ fontWeight: 700 }}
        disabled
      >
        TODO: Match Exisiting Transaction
      </Button>
      <Button
        // @ts-ignore
        variant="secondary"
        onClick={onQuickAddClick}
        sx={{ fontWeight: 700 }}
        disabled
      >
        TODO: Disregard
      </Button>
    </Box>
  );
};
