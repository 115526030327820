import envConfig from "../../../config";
import { useQuery } from "../../../hooks/useQuery";
import { EXAMPLE_GET_CONTACTS_RESPONSE } from "../../../data/example-get-contacts-response";
import { ResponseBoolean } from "../../../types/api/common";

const contactsUrl = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/contacts/get-selection-list?activeYN=Y&typeCS=C&BaseHostURL=${envConfig.mainServiceUrl}`;

// TODO: Move it somewhere else - probably types
export interface Contact {
  cntId: number;
  cntCode: string;
  cntName: string;
  cntTaxCode: string;
  cntTaxCodeFull: string;
  cntEmail: string;
  cntPhoneNo: string;
}

export interface ContactsResponse {
  ListOfContacts: Contact[];
  errorMessage: ResponseBoolean;
  successYN: ResponseBoolean;
}

export const useListOfContacts = () => {
  const { isLoading, isError, error, data, refetch } =
    useQuery<ContactsResponse>(contactsUrl);

  if (process.env.NODE_ENV === "development") {
    return {
      isLoading: false,
      isError: false,
      error: null,
      data: EXAMPLE_GET_CONTACTS_RESPONSE,
      refetch: () => {},
    };
  }

  return { isLoading, isError, error, data, refetch };
};
